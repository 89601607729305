import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "@material-ui/core";
import { Button, Col, FormGroup, Row } from "reactstrap";
import moment from "moment";
import CustomCalendar from "components/Calendar/CustomCalendar";
import api from "components/API/api";
import { startOfDay } from "utils/dateUtils";
import { startOfNextDay } from "utils/dateUtils";
import { Bar } from "react-chartjs-2";
import { useModal } from "context/ModalContext";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import { useBusiness } from "context/BusinessContext";
import { morningTimeRange } from "utils/dateUtils";
import { afternoonTimeRange } from "utils/dateUtils";
import { nightTimeRange } from "utils/dateUtils";
export default function DailyStatsModal({ openModal, handleCloseModal }) {
  const [stats, setStats] = useState({
    morning: {
      backup: 0,
      selected: 0,
      required: 0,
    },
    afternoon: {
      backup: 0,
      selected: 0,
      required: 0,
    },
    night: {
      backup: 0,
      selected: 0,
      required: 0,
    },
  });

  console.log(stats);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment());
  const { business } = useBusiness();
  const { addAlert } = useModal();

  const fetchStat = ({ start, end }) => {
    console.log(start, end);
    return api()
      .get("/selected-for-work/stats", {
        params: {
          from: moment(start).format(),
          till: moment(end).format(),
          business: business.profile?.business?._id,
        },
      })
      .then(({ data }) => data);
  };

  useEffect(() => {
    setLoading(true);
    const morningPromise = fetchStat(morningTimeRange(date));
    const afternoonPromise = fetchStat(afternoonTimeRange(date));
    const nightPromise = fetchStat(nightTimeRange(date));
    setLoading(true);
    Promise.all([morningPromise, afternoonPromise, nightPromise])
      .then(([morningStat, afternoonStat, nightStat]) => {
        setStats({
          morning: morningStat,
          afternoon: afternoonStat,
          night: nightStat,
        });
      })
      .catch((e) =>
        addAlert({ title: e.response?.data?.message || e.message, error: true })
      )
      .finally(() => setLoading(false));
  }, [date]);

  const graph = (stats, label) => {
    return {
      labels: ["Total Required", "Total Selected", "Total Backup"],
      datasets: [
        {
          label: label,
          data: [
            stats?.required || 0,
            stats?.selected || 0,
            stats?.backup || 0,
          ],
          backgroundColor: [
            "rgba(150, 100, 150, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(155, 106, 86, 1)",
          ],
          // borderColor: [

          // ],
        },
      ],
    };
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup
          style={{ width: 800, backgroundColor: "#fff", borderRadius: 10 }}
        >
          {loading && <LoadingOverlay />}
          <div style={{ padding: 25, marginTop: 20 }}>
            <CustomCalendar
              inputProps={{
                placeholder: "Date Picker Here",
              }}
              initialDate={date}
              onChangeDate={(date) => {
                setDate(date);
              }}
              myDate={date}
            />
            <Row>
              <Col>
                <Bar
                  data={graph(stats.morning, "Morning")}
                  width={100}
                  height={100}
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
              <Col>
                <Bar
                  data={graph(stats.afternoon, "Afternoon")}
                  width={100}
                  height={100}
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
              <Col>
                <Bar
                  data={graph(stats.night, "Night")}
                  width={100}
                  height={100}
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            </Row>
            <div style={{ position: "absolute", right: "20px", top: "10px" }}>
              <div onClick={handleCloseModal} style={{ cursor: "pointer" }}>
                X
              </div>
            </div>
          </div>
        </FormGroup>
      </Modal>
    </div>
  );
}
