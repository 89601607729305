/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";
import ReactTable from "react-table";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
  FormText,
  Spinner,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { validateEmail } from "components/Utilities/utilities.js";
import api from "components/API/api";
import { useBusiness } from "context/BusinessContext";

function StaffProfile() {
  const { business } = useBusiness();
  const [values, setValues] = useState({
    staffId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [disableEdit, setDisableEdit] = useState(false); // cannot edit business hr profiles

  const [role, setRole] = useState({
    value: "BusinessStaff",
    label: "Business Staff",
  });
  const [businesses, setBusinesses] = useState(null);
  const [selectedShift, setSelectedShift] = useState([]); // supervisor shift
  const [businessesList, setBusinessesList] = useState([]);

  const rolesList = [
    { value: "BusinessStaff", label: "Business Staff" },
    { value: "BusinessShiftSupervisor", label: "Business Shift Supervisor" },
    { value: "BusinessQrSignin", label: "Business Qr Signin" },
  ];
  const shiftTypes = [
    { value: "Morning", label: "Morning" },
    { value: "Afternoon", label: "Afternoon" },
    { value: "Night", label: "Night" },
  ];

  const [alert, setAlert] = useState(null);
  const [changeLog, setChangeLog] = useState([]);
  const [loggedUserRole, setLoggedUserRole] = useState(null); // logged in user role

  //Errors
  const [errors, setErrors] = useState({
    errorFirstName: "",
    errorLastName: "",
    errorPhone: "",
    errorEmail: "",
    errorRole: "",
    errorPassword: "",
    errorBusiness: "",
    errorSelectedShift: "",
  });

  //Loading
  const [isLoading, setIsLoading] = useState(false);
  const [pageTabs, setPageTabs] = useState("profile");

  useEffect(() => {
    business.profile && fetchData();
  }, [business]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleUserLeave);
    return () => {
      window.removeEventListener("beforeunload", handleUserLeave);
    };
  }, []);

  const fetchBusinesses = async () => {
    try {
      const { data } = await api().get(`/business/list`, {
        params: { slug: "hd-slug" },
      });
      if (business?.profile?.length && data?.length) {
        // console.log(business.profile)
        let tempBusinessList = [];
        data.map((b) => {
          let search = business.profile.find(
            (prof) => prof?.business?._id === b._id
          );
          search &&
            tempBusinessList.push({
              value: b._id,
              label: b.name,
            });
        });
        setBusinessesList(tempBusinessList);
        return tempBusinessList;
      }
    } catch (err) {
      console.log(err);
      console.log("Error while fetching business list");
    }
  };

  // fetch user data
  const fetchData = () => {
    //geting staff Id from URL
    let selectedUrl = window.location.pathname.split("/");
    let selectedDocId = selectedUrl[3];
    // console.log("i amd,", selectedDocId);
    setIsLoading(true); // Start the loading
    api()
      .get(`/users/business/${selectedDocId}`)
      .then(async (res) => {
        let data = res.data[0];
        console.log("Staff profile", data);
        const tempValues = values;
        tempValues.firstName = data.firstName;
        tempValues.staffId = data._id;
        tempValues.lastName = data.lastName;
        tempValues.email = data.email;
        tempValues.phone = data.phone;

        setValues(tempValues);

        const role =
          data.profile[0].role === "BusinessHr"
            ? { value: "BusinessHr", label: "Business Hr" }
            : rolesList.find((role) => role.value === data.profile[0].role);
        if (role) setRole(role);
        if (data.profile[0].role === "BusinessHr") setDisableEdit(true);

        if (data.profile[0].role === "BusinessShiftSupervisor") {
          const tempSelectedShift = [];
          shiftTypes.map((type) => {
            if (
              data?.profile[0]?.shiftType &&
              data?.profile[0]?.shiftType[`${type.value}`]
            ) {
              tempSelectedShift.push(type);
            }
          });
          setSelectedShift(tempSelectedShift);
        }

        let profileBusiness = [];
        data.profile.map((prof) => {
          profileBusiness.push(prof.business);
        });
        const businessList = await fetchBusinesses();
        let tempBusinesses = [];
        console.log("businessList", businessList);
        // console.log("profileBusiness", profileBusiness);
        businessList &&
          profileBusiness.map((profBusiness) => {
            let business = businessList.find((b) => b.value === profBusiness);
            if (business) {
              tempBusinesses.push(business);
            }
          });
        setBusinesses(
          data.profile[0].role === "BusinessHr"
            ? tempBusinesses
            : tempBusinesses[0]
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.response?.data?.message || err.message);
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  const handleSubmit = async (docId) => {
    let tempErrors = errors; // copy of error state
    let hasError = false;

    // Start of Error Handling
    if (values.firstName == null || values.firstName == "") {
      tempErrors.errorFirstName = "* Must have a first name.";
      hasError = true;
    } else {
      tempErrors.errorFirstName = "";
    }

    if (values.lastName == null || values.lastName == "") {
      tempErrors.errorLastName = "* Must have a last name.";
      hasError = true;
    } else {
      tempErrors.errorLastName = "";
    }

    if (values.phone == null || values.phone == "") {
      tempErrors.errorPhone = "* Must have a phone number."; // No phone number
      hasError = true;
    } else {
      if (
        isNaN(values.phone) == true ||
        values.phone.length != 10 ||
        values.phone < 0
      ) {
        tempErrors.errorPhone =
          "* Please input a valid phone number in the format ###-###-####."; //invalid phone number
        hasError = true;
      } else {
        tempErrors.errorPhone = "";
      }
    }

    if (values.email == null || values.email == "") {
      tempErrors.errorEmail = "* Must have an email"; // No email text
    } else {
      if (values.email != null && validateEmail(values.email) === false) {
        tempErrors.errorEmail = "* Please input a valid email"; //invalid email text
        hasError = true;
      } else {
        tempErrors.errorEmail = "";
      }
    }
    if (role == null || role == "") {
      tempErrors.errorRole = "* Must have a role"; // No role
      hasError = true;
    } else {
      tempErrors.errorRole = "";
    }

    if (role?.value == "BusinessShiftSupervisor" && !selectedShift?.length) {
      tempErrors.errorSelectedShift = "* Must select a shift type"; // No shift type
      hasError = true;
    } else {
      tempErrors.errorSelectedShift = "";
    }

    if (businesses == null || businesses == "") {
      tempErrors.errorBusiness = "* Must have a business";
      hasError = true;
    } else {
      tempErrors.errorBusiness = "";
    }

    if (hasError) {
      setErrors({ ...tempErrors });
      return;
    } else {
      let dataToUpdate = null;
      let reloadBusinessList = businessesList; // save business list
      // console.log("this.state.businesses", this.state.businesses);
      const tempBusinessList =
        role?.value === "BusinessHr"
          ? businesses.map((business) => business.value)
          : [businesses.value];
      // console.log(tempBusinessList);
      dataToUpdate = {
        userId: values.staffId,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
        profileType: role.value,
        business: tempBusinessList,
      };

      if (role.value === "BusinessShiftSupervisor") {
        const shiftType = {
          Morning: false,
          Afternoon: false,
          Night: false,
        };
        selectedShift.map((shift) => {
          shiftType[`${shift.value}`] = true;
        });
        // console.log("shiftType", shiftType);
        dataToUpdate.shiftType = shiftType;
      }

      // console.log(dataToUpdate)
      setIsLoading(true);
      try {
        const respPatch = await api().patch(
          "/users/update-manager",
          dataToUpdate
        );
        setIsLoading(false);
        setAlert(
          <SweetAlert
            success
            title="staff successfully created"
            onConfirm={hideAlert}
          />
        );
      } catch (error) {
        console.log(error.response);
        setIsLoading(false);
        setAlert(
          <SweetAlert
            error
            title={error?.response?.data?.message || error.message}
            onConfirm={hideAlert}
          />
        );
        console.log(error);
      }
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={
          <Spinner color="light" style={{ width: "3rem", height: "3rem" }} />
        }
        onConfirm={hideAlert}
        showConfirm={false}
      />
    );
  };

  return (
    <>
      <div className="content">
        {isLoading ? (
          loadingOverlay()
        ) : (
          <>
            <Row>
              <Col>
                <Card className="no-transition">
                  <CardHeader>
                    <Row>
                      <Col md="6">
                        <CardTitle tag="h4">
                          {" "}
                          Profile of {`${values.firstName}`}{" "}
                          {`${values.lastName}`}
                        </CardTitle>
                      </Col>
                      <Col md="6" align="right">
                        {/* <Button
                        type="submit"
                        color="default"
                        outline
                        onClick={this.loadShiftHistory}
                      >
                        Full Shift History
                      </Button> */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* <Nav
                      className="nav-pills-primary nav-pills-icons justify-content-center"
                      pills
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          role="tablist"
                          className={pageTabs === "profile" ? "active" : ""}
                          onClick={() => setPageTabs("profile")}
                        >
                          <i className="now-ui-icons objects_umbrella-13" />
                          Edit Profile
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          role="tablist"
                          className={pageTabs === "changeLog" ? "active" : ""}
                          onClick={() => setPageTabs("changeLog")}
                        >
                          <i className="now-ui-icons ui-2_settings-90" />
                          Change Log
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      className="tab-space tab-subcategories"
                      activeTab={pageTabs}
                    >
                      <TabPane tabId="profile"> */}
                    <CardHeader>
                      <CardTitle tag="h4">Edit Profile</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form action="/" className="form-horizontal" method="get">
                        <Row>
                          <Label sm="2">*First Name</Label>
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                disabled={disableEdit}
                                defaultValue={`${values.firstName}`}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    firstName: e.target.value,
                                  })
                                }
                                placeholder="First Name"
                                type="text"
                              />
                              <FormText color="danger" tag="span">
                                {errors.errorFirstName}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="2">*Last Name</Label>
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                disabled={disableEdit}
                                defaultValue={`${values.lastName}`}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    lastName: e.target.value,
                                  })
                                }
                                placeholder="Last Name"
                                type="text"
                              />
                              <FormText color="danger" tag="span">
                                {errors.errorLastName}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="2">* Phone Number</Label>
                          <Col md="5">
                            <FormGroup>
                              <MaskedInput
                                mask={[
                                  "(",
                                  /[1-9]/,
                                  /\d/,
                                  /\d/,
                                  ")",
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                disabled={disableEdit}
                                defaultValue={`${values.phone}`}
                                onChange={(e) => {
                                  let input = e.target.value;
                                  let replacedPhone = input.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setValues({
                                    ...values,
                                    phone: replacedPhone,
                                  });
                                }}
                                placeholder="Phone Number"
                                className="form-control"
                                maxLength="15"
                              />
                              <FormText color="danger" tag="span">
                                {errors.errorPhone}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="2">* Email</Label>
                          <Col sm="5">
                            <FormGroup>
                              <Input
                                disabled={true}
                                defaultValue={`${values.email}`}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    email: e.target.value,
                                  })
                                }
                                placeholder="Contact Email"
                                type="text"
                              />
                              <FormText color="danger" tag="span">
                                {errors.errorEmail}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Label sm="2">* Role</Label>
                          <Col sm="5">
                            <FormGroup>
                              <Select
                                isDisabled={disableEdit}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                // isDisabled={true}
                                options={rolesList}
                                value={role}
                                onChange={(value) => {
                                  if (
                                    role.value === "BusinessHr" &&
                                    value.value !== "BusinessHr"
                                  )
                                    setBusinesses(null);
                                  setRole(value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        {role?.value === "BusinessShiftSupervisor" && (
                          <Row>
                            <Label sm="2">* Shift Types</Label>
                            <Col sm="5">
                              <FormGroup>
                                <Select
                                  isDisabled={disableEdit}
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  isMulti
                                  options={shiftTypes}
                                  onChange={(value) => {
                                    setSelectedShift(value);
                                  }}
                                  value={selectedShift}
                                />
                                <FormText color="danger" tag="span">
                                  {errors.errorSelectedShift}
                                </FormText>
                              </FormGroup>
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <Label sm="2">* Businesses</Label>
                          <Col sm="5">
                            <FormGroup>
                              <Select
                                isDisabled={disableEdit}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                isMulti={role?.value === "BusinessHr"}
                                options={businessesList}
                                value={businesses}
                                onChange={(value) => {
                                  setBusinesses(value);
                                }}
                                placeholder="Select businesses"
                              />
                              <FormText color="danger" tag="span">
                                {errors.errorBusiness}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>

                        <FormGroup align="right">
                          <Button
                            color="default"
                            outline
                            disabled={disableEdit}
                            onClick={() => handleSubmit()}
                          >
                            Save Changes
                          </Button>
                        </FormGroup>
                      </Form>
                    </CardBody>
                    {/* </TabPane>
                      <TabPane tabId="changeLog">
                        <CardHeader>
                          <CardTitle tag="h4">Change Log</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <ReactTable
                            data={changeLog}
                            filterable
                            columns={[
                              {
                                Header: "Admin",
                                accessor: "admin",
                              },
                              {
                                Header: "Time Changed",
                                accessor: "time",
                              },
                              {
                                Header: "Action Taken",
                                accessor: "action",
                              },
                            ]}
                            defaultPageSize={10}
                            showPaginationTop={true}
                            showPaginationBottom={false}
                            className="-striped -highlight primary-pagination"
                          />
                        </CardBody>
                      </TabPane>
                    </TabContent> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
        {alert}
      </div>
    </>
  );
}

export default StaffProfile;
