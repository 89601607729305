import React, { useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import SweetAlert from "react-bootstrap-sweetalert";
import { baseURL as ENDPOINT } from "components/API/api";
import AgencyContext from "./BusinessContext";
import moment from "moment";
import api from "components/API/api";
export const SocketContext = React.createContext({});

export const SocketProvider = function ({ children }) {
  const [socket, setSocket] = useState(null);
  const [alert, setAlert] = useState(null);
  const { agency } = useContext(AgencyContext);
  const [unreadMessage, setUnreadMessage] = useState([]);

  const [newMessage, setNewMessage] = useState(false);

  const resetNewMessage = () => {
    setNewMessage(false);
  };

  useEffect(() => {
    setSocket(io(ENDPOINT));

    return () => {
      if (socket) {
        socket.disconnect();
        socket.off();
        setSocket(null);
      }
    };
  }, []);

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    if (socket && agency?.agency?._id && agency?.user) {
      socket.emit("join", { room: agency?.agency?._id }, (resp) => {
        console.log("join");
      });

      socket.on("requiredPeopleUpdated", (res) => {
        const data = res.source;
        setAlert(
          <SweetAlert
            info
            title={`${data.userName} changed the Total Required Workers from ${data.from} to ${
              data.to
            } for Shift [${moment(data.shiftStart).format("MMM Do YYYY, h:mm a")}-${moment(
              data.shiftEnd,
            ).format("MMM Do YYYY, h:mm a")}] for Business ${data.businessName}`}
            onConfirm={hideAlert}
            style={{
              fontSize: 10,
            }}
          />,
        );
      });

      socket.on("message", (resp) => {
        if (resp.senderId !== agency?.user._id) {
          setNewMessage(true);
        }
      });
    }
  }, [socket, agency]);

  useEffect(() => {
    if (agency.user && localStorage.Token) {
      api()
        .get("/message/some-unread")
        .then(({ data }) => {
          setUnreadMessage(data.unread);
        })
        .catch((e) => {
          console.log(e.response?.data?.message || e.message);
        });
    }
  }, [agency]);

  return (
    <SocketContext.Provider value={{ socket, newMessage, resetNewMessage, unreadMessage }}>
      {children}
      {alert}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
