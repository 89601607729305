import api from "components/API/api";
import useWindowDimensions from "components/Hooks/useWindowDimensions";
import { useModal } from "context/ModalContext";
import React, { useEffect, useRef, useState } from "react";
import QrReader from "react-qr-reader";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { FlipCameraIos } from "@material-ui/icons";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

const CAMERA_TIME = 60; //sec

function SignIn() {
  let pageHeader = React.createRef();
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(null);
  const [qrCodeSize, setQrCodeSize] = useState(0);
  const [facingMode, setFacingMode] = useState("user");
  const [show, setShow] = useState("clockin");
  const scanned = useRef([]);
  const lastScanned = useRef(new Date());
  const { height, width } = useWindowDimensions();
  const [count, setCount] = useState(0)

  useEffect(() => {
    setQrCodeSize(Math.floor(Math.min(height, width) * 0.6));
  }, [width, height]);

  useEffect(() => {
    fetchBusiness();
  }, []);

  const fetchBusiness = async () => {
    const businessId = localStorage.getItem("BusinessId");
    try {
      const { data } = await api().get(`business/${businessId}`);
      setBusiness(data.business);
      console.log(data);
    } catch (e) {
      addAlert({ error: true, title: e?.response?.data?.message || e.message });
    }
  };

  const { addAlert } = useModal();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  useEffect(() => {
    if (localStorage.SToken) {
      let token = JSON.parse(localStorage.getItem("SToken"));
      if (token) {
        api({}, token)
          .get("/users/login/validate-token")
          .then(({ data }) => {
            console.log("logged in!");
          })
          .catch((e) => {
            localStorage.removeItem("BusinessId");
            localStorage.removeItem("SToken");
            history.push(`/qr/login`);
          });
      }
    }
  }, []);

  useEffect(() => {
    const now = new Date();
    let millisTill6 =
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 6, 0, 0, 0) -
      now;
    let millisTill14 =
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 14, 0, 0, 0) -
      now;
    let millisTill22 =
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22, 0, 0, 0) -
      now;

    let logoutIn = 0;
    if (millisTill6 > 0) {
      logoutIn = millisTill6;
    } else if (millisTill14 > 0) {
      logoutIn = millisTill14;
    } else if (millisTill22 > 0) {
      logoutIn = millisTill22;
    } else {
      logoutIn = millisTill6 + 86400000;
    }
    const timeout = setTimeout(function () {
      logout();
    }, logoutIn);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const history = useHistory();

  const logout = async () => {
    await api({}, JSON.parse(localStorage.getItem("SToken")))
      .post("/users/logout")
      .then(() => {
        localStorage.removeItem("SToken");
        history.push(`/qr/login`);
        return true;
      })
      .catch((e) => {
        localStorage.removeItem("SToken");
        history.push(`/qr/login`);
        console.log(e);
      });
  };

  const signIn = async (result) => {
    lastScanned.current = new Date();
    let token = JSON.parse(localStorage.getItem("SToken"));
    const { data } = await api({}, token).post("/connectCode/business/signIn", {
      code: result,
    });
    addAlert({
      success: true,
      title: "Successfully Checked In",
      children: (
        <>
          <div>
            {data?.selectedForWork?.check?.in?.time &&
              `Signed In: ${moment(data.selectedForWork.check.in.time).format(
                "hh:mm a"
              )}`}
          </div>
          <div>
            {data.selectedForWork?.check?.out?.time &&
              `Signed Out: ${moment(data.selectedForWork.check.out.time).format(
                "hh:mm a"
              )}`}
          </div>
        </>
      ),
    });
  };

  const checkTime = async (result) => {
    lastScanned.current = new Date();
    let token = JSON.parse(localStorage.getItem("SToken"));
    const { data } = await api({}, token).get("/connectCode/business/signIn", {
      params: { code: result },
    });
    addAlert({
      success: true,
      title: "Your Check Time Log: ",
      children: (
        <>
          <div>
            {data?.selectedForWork?.check?.in?.time &&
              `Signed In: ${moment(data.selectedForWork.check.in.time).format(
                "hh:mm a"
              )}`}
          </div>
          <div>
            {data.selectedForWork?.check?.out?.time &&
              `Signed Out: ${moment(data.selectedForWork.check.out.time).format(
                "hh: mm a"
              )}`}
          </div>
        </>
      ),
    });
  };

  const readCode = async (result) => {
    lastScanned.current = new Date();

    scanned.current = scanned.current.filter((_scanned) =>
      moment(_scanned.time).isAfter(moment().subtract(5, "second"))
    );
    if (scanned.current.find((scan) => scan.code === result)) {
      return;
    }
    scanned.current.push({
      time: new Date(),
      code: result,
    });
    // return;
    try {
      setLoading(true);
      show === "clockin" ? await signIn(result) : await checkTime(result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      addAlert({
        danger: true,
        title: "Check in/out failed!",
        children: e?.response?.data.message || e?.message,
      });
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("assets/img/sections/david-marcu.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <h1 className="title">
              {show === "clockin"
                ? "Clock In/Out"
                : show === "timecheck"
                ? "View Time History"
                : ""}
            </h1>
            {show && (
              <>
                <p>
                  Show your QR code to the camera and hold to clock in/out.
                  <br></br>
                  Make sure to increase your screen brightness so the QR scanner can read your code!
                </p>
                <QrReader
                  delay={1000}
                  onError={handleError}
                  onScan={(code) => {
                    if (
                      moment(lastScanned.current).isBefore(
                        moment().subtract(CAMERA_TIME, "second")
                      )
                    ) {
                      return;
                    }
                    
                    //QR reader automatically stalls after approx. 150 reads. Refreshing solves this issue
                    if (count > 30) {
                      window.location.reload();
                      }
                    let newCount = count + 1
                    setCount(newCount);

                    console.log(count,code);
                    code && readCode(code);
                  }}
                  style={{ width: `${qrCodeSize}px`, margin: "auto" }}
                  facingMode={facingMode}
                />
              </>
          )}
            <div className="motto">
              <h1 className="title">HD Group of Companies</h1>
              <h3 className="description">{business?.name}</h3>
              <br />
              {/* <Button
                className="btn-round mr-1"
                color="neutral"
                outline={show !== "clockin"}
                onClick={() => {
                  setShow("clockin");
                  lastScanned.current = new Date();
                }}
              >
                Clock In/Out
              </Button>
              <Button
                className="btn-round"
                color="neutral"
                type="button"
                outline={show !== "timecheck"}
                onClick={() => {
                  setShow("timecheck");
                  lastScanned.current = new Date();
                }}
              >
                Check Time
              </Button> */}
            </div>
          </Container>
        </div>
        <div >
        <Button
          onClick={()=>setFacingMode(facingMode === "user" ? "environment" : "user")}
          color="neutral"
          outline
          className="btn"
          style={{
            position: "fixed",
            right: "100px",
            top: "10px",
            zIndex: "200",
          }}
        >
          <FlipCameraIos style={{ marginLeft: -1 }} />
        </Button>
        {/* <Button
          className="btn-round"
          color="neutral"
          type="button"
          outline
          onClick={logout}
          style={{
            position: "fixed",
            right: "10px",
            top: "10px",
            zIndex: "200",
          }}
        >
          Exit
        </Button> */}
        </div>
      </div>
    </>
  );
}

export default SignIn;
