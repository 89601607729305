import Background from "assets/img/agency/charles-forerunner-3fPXt37X6UQ-unsplash.jpg";
import api from "components/API/api";
import { useBusiness } from "context/BusinessContext";
import { useModal } from "context/ModalContext";
import React, { useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner as ReactSpinner,
} from "reactstrap";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const business = useBusiness();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [marginTop, setMarginTop] = useState("50px");

  const { addAlert } = useModal();

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  useEffect(() => {
    if (localStorage.Token) {
      let token = JSON.parse(localStorage.getItem("Token"));
      if (token) {
        api()
          .get("/users/login/validate-token")
          .then(({ data }) => {
            processLoginData({
              user: data,
              token: token,
            });
          })
          .catch((e) => {
            localStorage.Token = null;
            business.businessDispatch({ type: "REMOVE_USER" });
          });
      }
    }
  }, []);

  // useEffect(() => {
  //   window.addEventListener("resize", windowCheck);
  // }, []);

  const processLoginData = ({ user, token }) => {
    let profile = user.profile.find((p) => !!p.business);
    if (profile?.business?._id && profile?.role !== "BusinessQrSignin") {
      let businessId = profile?.business?._id;
      // console.log(businessId)
      localStorage.setItem("Token", JSON.stringify(token));
      localStorage.setItem("BusinessId", businessId);
      business.businessDispatch({
        type: "SET_USER",
        payload: { user: user },
      });
      business.businessDispatch({
        type: "SET_PROFILE",
        payload: { profile: user.profile },
      });
      business.businessDispatch({
        type: "SET_LOADING",
        payload: { loading: false },
      });
      profile.role === "BusinessHr"
        ? history.push(`/admin/live-business`)
        : history.push(`/admin/live-selected-users`);
    } else {
      addAlert({
        error: true,
        title: "Invalid email or password, please try again.",
      });
    }
  };

  const buttonClick = async () => {
    setLoading(true);
    try {
      let send = {
        email,
        password,
      };
      business.businessDispatch({
        type: "SET_LOADING",
        payload: { loading: true },
      });
      const { data } = await api().post("/users/business/login", send);
      // console.log("login data", data)
      processLoginData(data);
    } catch (error) {
      addAlert({
        error: true,
        title: "Invalid email or password, please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  //What : Changes the marginTop property for the Login Component to ensure a dynamically centered component
  //Input : No input required.
  //Output : Changes marginTop property in state

  // const windowCheck = () => {
  //   if (window.innerHeight > 550) {
  //     setMarginTop("calc(50vh - 230px)");
  //   } else {
  //     setMarginTop("50px");
  //   }
  // };
  const forgotPassword = () => {
    /* 2023-04-05 Cleanup prior to Dufflet Meeting: fix forgot pw path */
    history.push(`forgot-password`);
  };

  return (
    <div
      className="page-header"
      ref={pageHeader}
      style={{
        backgroundImage:
          "url(" + require("assets/img/sections/david-marcu.jpg") + ")",
      }}
    >
      <div className="filter" />
      <div className="content-center">
        <div className="login-page">
          {alert}

          <div
            className="full-page-background"
            style={{
              backgroundColor: "rgb(221,221,221)",
              backgroundImage: `url(${Background})`,
              zIndex: 2,
            }}
          />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="6" md="6" xs="11">
                <Form
                  style={{
                    marginTop: marginTop,
                    fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                  }}
                  action=""
                  className="form"
                  method=""
                >
                  {loading && (
                    <div style={{ position: "fixed", top: "50%", left: "50%" }}>
                      <ReactSpinner
                        className="text-center"
                        type="border"
                        color="secondary"
                        size="6"
                      />
                    </div>
                  )}
                  <Card className="card-login no-transition">
                    <CardHeader style={{ height: "80px", paddingTop: "10px" }}>
                      <CardHeader>
                        <h3 className="header text-center">LOGIN</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          style={{
                            fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                          }}
                          placeholder="Email"
                          type="email"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          autoComplete="off"
                          value={password}
                          style={{
                            fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                          }}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                      <br />
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        className="btn-round mb-3"
                        // color="warning"
                        // href="#pablo"
                        onClick={buttonClick}
                        // style={{backgroundColor : '#004A8C'}}
                        style={{ backgroundColor: "rgb(30,30,30)" }}
                      >
                        Login
                      </Button>
                      <Button
                        block
                        className="btn-round mb-3"
                        onClick={forgotPassword}
                        style={{ backgroundColor: "rgb(30,30,30)" }}
                      >
                        Forgot Password
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
            <div className="motto">
              <h1 className="title">HD Group of Companies</h1>
              <br />
              <Button
                className="btn-round mr-1"
                color="neutral"
                onClick={() => {
                  history.push("/auth/login");
                }}
              >
                Supervisor Portal
              </Button>
              <Button
                className="btn-round"
                color="neutral"
                type="button"
                outline
                onClick={() => {
                  history.push("/qr/login");
                }}
              >
                QR Clock In
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Login;
