import { Equalizer, Visibility } from "@material-ui/icons";
import api from "components/API/api";
import CustomCalendar from "components/Calendar/CustomCalendar";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import { useBusiness } from "context/BusinessContext";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";
import Select from "react-select";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import {
  afternoonTimeRange,
  morningTimeRange,
  nightTimeRange,
  allDayRange,
} from "utils/dateUtils";
// import matchSorter from 'match-sorter';
import { startOfDay, startOfNextDay } from "utils/dateUtils.js";
import ClockInCell from "./ClockInCell";
import DailyStatsModal from "./DailyStatsModal";
// import PinCell from "./PinCell";
import { getDay } from "utils/dateUtils";
import { shiftTimeType } from "utils/dateUtils";
import { todayTomorrow } from "utils/dateUtils";

const requiredWorkerOptions = [];
for (let i = 0; i <= 100; i++) {
  requiredWorkerOptions.push({
    value: i,
    label: `${i}`,
  });
}

const selectedForWorkStatus = {
  Applied: 0 /****when user applies to a shift */,
  selected: 1 /***when user is selected for shift */,
  Backup: 2, //when backup is created
  Rejected: 3, //when rejected
  UnScheduled: 4, //when unscheduled by admins
  Dropped: 5, //when shift dropped by user
};

export const unScheduleReasonText = [
  "Employee bailed",
  "Employee didn't pickup call",
  "Employee can't go for other reasons.",
  "Employee was scheduled by Mistake.",
  "Shift Cancelled due to less work",
  "Shift was Cancelled by plant",
  "Employee was sent back",
];

const LiveSelectedUsers = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { business } = useBusiness();
  const [alert, setAlert] = useState(null);
  const [pages, setPages] = useState(0);
  const [timer, setTimer] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [sorted, setSorted] = useState([]);
  const [requiredWorkers, setRequiredWorkers] = useState({
    Morning: { value: 0, label: "0" },
    Afternoon: { value: 0, label: "0" },
    Night: { value: 0, label: "0" },
  });
  const [shifts, setShifts] = useState({
    Morning: 0,
    Afternoon: 0,
    Night: 0,
  });
  const [dailyStatModal, setDailyStatModal] = useState(false);
  const [filter, setFilter] = useState({
    startDate: localStorage.getItem("selectedDate")
      ? localStorage.getItem("selectedDate")
      : new Date(),
    endDate: new Date(),
    query: "",
    date: moment().add(1, "days"),
    fromTime: null,
    toTime: null,
    shiftFunction: null,
  });

  const [selectedForWorkData, setSelectedForWorkData] = useState([]);
  const [selectedShiftFilter, setSelectedShiftFilter] = useState("All");

  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 5,
    field: "hdId",
    sort: "desc",
    filter: [],
  });

  const [editTabs, setEditTabs] = useState([
    { name: "P.O" },
    { name: "Morning" },
    { name: "Afternoon" },
    { name: "Night" },
  ]);

  const [shiftTabs, setShiftTabs] = useState([
    { name: "All" },
    { name: "Morning" },
    { name: "Afternoon" },
    { name: "Night" },
  ]);

  const [role, setRole] = useState("");

  useEffect(() => {
    if (business) {
      let profile = business.profile?.length
        ? business.profile[0]
        : business.profile;
      //  console.log("business.profile", business?.profile);
      // console.log("profile", profile);
      //  console.log("profile shiftType", profile?.shiftType);
      if (profile?.role === "BusinessShiftSupervisor" && profile.shiftType) {
        const tempTabs = [];
        profile.shiftType.Morning && tempTabs.push({ name: "Morning" });
        profile.shiftType.Afternoon && tempTabs.push({ name: "Afternoon" });
        profile.shiftType.Night && tempTabs.push({ name: "Night" });
        if (tempTabs?.length < 3) {
          setSelectedShiftFilter(tempTabs[0].name);
          setShiftTabs(tempTabs);
          setEditTabs(tempTabs);
        }
      }
      profile?.role && setRole(profile?.role);
    }
  }, [business]);

  useEffect(() => {
    async function setTimeRange() {
      const timeRange = getTimeRange();
      if (role && timeRange) {
        setFilter({
          ...filter,
          fromTime: timeRange.start && moment(timeRange.start).format(),
          toTime: timeRange.end && moment(timeRange.end).format(),
        });
      }
    }
    setTimeRange();
  }, [selectedShiftFilter, filter.startDate]);

  const getTimeRange = () => {
    let timeRange;
    switch (selectedShiftFilter) {
      case "Morning":
        timeRange = morningTimeRange(filter.startDate);
        break;
      case "Afternoon":
        timeRange = afternoonTimeRange(filter.startDate);
        break;
      case "Night":
        timeRange = nightTimeRange(filter.startDate);
        break;
      default:
        timeRange = {
          start: startOfDay(filter.startDate),
          end: startOfNextDay(filter.startDate),
        };
    }
    return timeRange;
  };

  useEffect(() => {
    const businessId =
      business.profile?.business?.id ||
      (business.profile?.length && business.profile[0]?.business?.id);
    if (businessId) loadAllShifts(businessId, filter.startDate);
  }, [filter.startDate, business]);

  const loadAllShifts = function (selectedBusiness, date) {
    let tempShifts = {
      Morning: 0,
      Afternoon: 0,
      Night: 0,
    };
    let tempRequiredWorkers = {
      Morning: { value: 0, label: "0" },
      Afternoon: { value: 0, label: "0" },
      Night: { value: 0, label: "0" },
    };
    api()
      .get(`business/shifts/all`, {
        params: {
          businessId: selectedBusiness,
          start: startOfDay(date),
          end: startOfNextDay(date),
          day: getDay(date),
        },
      })
      .then(({ data }) => {
        // console.log("shifts", data);

        if (data.length) {
          data.map((shift) => {
            const timing = shiftTimeType(shift.start);
            tempShifts[timing] = tempShifts[timing] + 1;
            tempRequiredWorkers[timing] = {
              value:
                tempRequiredWorkers[timing].value + shift.noRequiredWorkers,
              label: `${
                tempRequiredWorkers[timing].value + shift.noRequiredWorkers
              }`,
            };
          });
        }

        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      })
      .finally(() => {
        setShifts({ ...tempShifts });
        setRequiredWorkers(tempRequiredWorkers);
      });
  };

  useEffect(() => {
    if (!business.loading) {
      fetchData();
    }
  }, [filter.fromTime, filter.shiftFunction, business]);
  const fetchData = async (filterOptions = reactTableState) => {
    // isFirstMount && setIsFirstMount(false);
    try {
      const businessId =
        business.profile?.business?.id ||
        (business.profile?.length && business.profile[0]?.business?.id);
      if (
        businessId &&
        role &&
        (role === "BusinessShiftSupervisor"
          ? filter.fromTime && filter.toTime
          : true)
      ) {
        setLoadingTable(true);

        const { data } = await api().get("/selected-for-work", {
          params: {
            ...filterOptions,
            from: filter.fromTime || startOfDay(filter.startDate),
            till: filter.toTime || startOfNextDay(filter.startDate),
            businessId,
            shiftFunction: filter.shiftFunction,
          },
        });
        // console.log("filter", filter)
        // console.log("fromTime", moment(filter.fromTime).format("hh:mm a"))
        // console.log("toTime", moment(filter.toTime).format("hh:mm a"))
        // console.log("selectedShiftFilter", selectedShiftFilter)
        // console.log("data", data)
        setSelectedForWorkData(
          transformRawData(data.selectedForWorks, filterOptions)
        );
        console.log("selectedForWorkData", selectedForWorkData);
        setPages(data.noPages);
        setLoadingTable(false);
      }
    } catch (error) {
      setLoadingTable(false);
    }
  };

  const transformRawData = (shiftRequest, filterOptions) => {
    // console.log(filterOptions);
    return shiftRequest.map((d, i) => {
      const clockIn = d.check?.in;
      const clockOut = d.check?.out;
      const workerPins = d.user.workerPin;
      const workerPin = workerPins.find(
        (pin) => pin.business === business?.profile?.business?._id
      );
      const supervisorApproval = d.supervisorApproval ? true : false;
      const supervisorApprovalOT = d.supervisorApprovalOT ? true : false;

      const defaultClockIn =
        d.check?.in?.time > d.start ? d.check.in.time : d.start;
      const defaultClockOut =
        d.check?.out?.time < d.end ? d.check.out.time : d.end;

      // always use the LATER of scheduled start and clock in. They never start early
      const defaultClockInToSubtract = d.check?.in?.time
        ? moment(defaultClockIn).add(30, "minutes")
        : moment(d.start).add(30, "minutes");

      let totalHours = "-";

      if (supervisorApprovalOT === true) {
        // overtime approval (more than scheduled end), use clock out
        totalHours =
          clockIn && clockOut
            ? moment(clockOut.time)
                .diff(defaultClockInToSubtract, "hours", true)
                .toFixed(2)
            : "-";
      } else if (supervisorApproval === true) {
        // regular approval, use default times or later clock in/earlier clock out if exists

        let regStart = d.check?.in?.time
          ? defaultClockInToSubtract
          : moment(d.start).add(30, "minutes");
        let regEnd = d.check?.out?.time ? defaultClockOut : d.end;

        totalHours = moment(regEnd).diff(regStart, "hours", true).toFixed(2);
      }
      return {
        _id: d._id,
        index: (filterOptions.page - 1) * filterOptions.size + (i + 1),
        userId: d.user._id,
        hdId: d.user.hdId,
        firstName: d.user.firstName,
        lastName: d.user.lastName,
        duplicated: d.user.duplicated,
        address: d.user.address,
        phone: d.user.phone,
        city: d.user.city,
        workerPins: d.user.workerPin,
        business: d.business,
        businessName: d.businessName,
        shift: d.shift._id,
        shiftName: d.shift.shiftName,
        status: d.status,
        lastAppActive:
          d.user.lastAppActive && moment(d.user.lastAppActive).calendar(),
        start: d.start,
        startTime: moment(d.start).format("hh:mm a"),
        endTime: moment(d.end).format("hh:mm a"),
        end: d.end,
        lastAppLoginTime: d.user.lastAppLogin
          ? moment(d.user.lastAppLogin).format("MMM Do YYYY, h:mm a")
          : null,
        actions: renderActionButtons(d),
        rowColor: d.status === selectedForWorkStatus.Backup ? "orange" : null,
        sinExpiry: d.user.sinExpiry,
        shiftFunction:
          d.shiftFunction?.name || d.shiftFunction?.name === 0
            ? d.shiftFunction?.name
            : "-",
        clockIn: clockIn,
        clockOut: clockOut,
        workerPin: workerPin,
        supervisorApproval: supervisorApproval,
        supervisorApprovalOT: supervisorApprovalOT,
        lastApprovalUpdate: d.supervisorName ? d.supervisorName : "",
        totalHours: totalHours,
      };
    });
  };

  const updateSupervisorApproval = (
    id,
    oldSupervisorApproval,
    oldSupervisorApprovalOT,
    isOT
  ) => {
    const newSupervisorApprovalId = business?.user?.id;
    const newSupervisorApprovalName = business?.user?.firstName;

    setIsLoading(true);

    let newSupervisorApproval = oldSupervisorApproval;
    let newSupervisorApprovalOT = oldSupervisorApprovalOT;

    // if OT is updating to true, set regular approval to true. Otherwise, just toggle
    if (isOT === true) {
      if (!oldSupervisorApprovalOT === true) {
        newSupervisorApproval = true;
      }
      newSupervisorApprovalOT = !oldSupervisorApprovalOT;
    } else {
      newSupervisorApproval = !oldSupervisorApproval;
    }

    api()
      .patch(`/selected-for-work/supervisor-approval/${id}`, {
        supervisorApprovalUpdate: newSupervisorApproval,
        supervisorApprovalOTUpdate: newSupervisorApprovalOT,
        supervisorIdUpdate: newSupervisorApprovalId,
        supervisorNameUpdate: newSupervisorApprovalName,
      })
      .then(() => {
        fetchData();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const SupervisorApprovalCheckbox = (rowData) => {
    return (
      <div className="actions-center">
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              onChange={() => {
                updateSupervisorApproval(
                  rowData.id,
                  rowData.supervisorApproval,
                  rowData.supervisorApprovalOT,
                  rowData.isOT
                );
              }}
              checked={
                rowData.isOT
                  ? rowData.supervisorApprovalOT
                  : rowData.supervisorApproval
              }
              id={"supervisorApprovalCheckbox"}
            />
            <span className="form-check-sign" />
            {rowData.isOT ? "Excess Hours" : "Reg Hours"}
          </Label>
        </FormGroup>
      </div>
    );
  };

  const openUserProfile = (userId) => {
    props.history.push(`/admin/user-profile/${userId}`);
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const renderActionButtons = (selectedForWork) => {
    let search = new URLSearchParams();
    search.append("date", selectedForWork.start);
    search.append("shift", selectedForWork.shift);

    return (
      <div className="actions-center">
        <Button
          onClick={() => {
            openUserProfile(selectedForWork.user._id);
          }}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  const _renderShiftTab = () => {
    let shiftFilterList = shiftTabs;

    return (
      <Nav
        className="nav-pills-primary nav-pills-icons justify-content-center"
        pills
        role="tablist"
      >
        {shiftFilterList.map((shift, i) => {
          return (
            <NavItem key={i}>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={selectedShiftFilter === shift.name ? "active" : ""}
                onClick={() => {
                  // setSelectedForWorkTableData(shift.data);
                  setSelectedShiftFilter(shift.name);
                }}
              >
                <i className="now-ui-icons objects_umbrella-13" />
                {`${shift.name}`}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    );
  };

  // console.log("selectedShiftFilter", selectedShiftFilter)

  /* 2023-04-05 Cleanup prior to Dufflet Meeting: Hide Filters (not functional) */
  const _renderShiftTable = () => {
    return (
      <ReactTable
        filterable
        data={selectedForWorkData}
        getTrProps={(state, rowInfo, instance) => {
          if (rowInfo?.row?._original?.rowColor) {
            return {
              style: {
                color: rowInfo?.row?._original?.rowColor,
              },
            };
          }
          return {};
        }}
        columns={[
          {
            Header: "#",
            accessor: "index",
            maxWidth: 50,
            sortable: false,
            filterable: false,
          },
          {
            Header: "HD Id",
            accessor: "hdId",
            maxWidth: 70,
            filterable: false,
          },
          {
            Header: "First Name",
            accessor: "firstName",
            filterable: false,
          },
          {
            Header: "Last Name",
            accessor: "lastName",
            filterable: false,
          },
          // {
          //   Header: "Address",
          //   accessor: "address",
          // },
          // {
          //   Header: "City",
          //   accessor: "city",
          // },
          // {
          //   Header: "Worker's Pin",
          //   accessor: "workerPin",
          //   filterable: false,
          //   Cell: (row) => {
          //     return (
          //       <PinCell
          //         workerPin={row.original.workerPin}
          //         // _id={row.original.workerPin && row.original.workerPin._id}
          //         onChange={() => {
          //           fetchData();
          //         }}
          //         workerId={row.original?.userId}
          //         businessId={business?.profile?.business?._id}
          //         businessName={business?.profile?.business?.name}
          //       />
          //     );
          //   },
          // },
          // {
          //   Header: "Phone",
          //   accessor: "phone",
          //   Cell: (row) => {
          //     let telephone = "tel:" + row.original.phone;
          //     return (
          //       <a
          //         href={telephone}
          //         style={{ color: "orange", textDecoration: "underline" }}
          //       >
          //         {row.original.phone}
          //       </a>
          //     );
          //   },
          //   maxWidth: 120,
          // },
          // {
          //   Header: "Business",
          //   accessor: "businessName",
          //   filterable: false,
          // },
          {
            Header: "Shift",
            accessor: "shiftName",
            filterable: false,
          },
          {
            Header: "Shift Function",
            accessor: "shiftFunction",
            filterable: false,
          },
          {
            Header: "Clock In",
            accessor: "clockIn",
            filterable: false,
            Cell: (row) => {
              return (
                <ClockInCell
                  clockTime={row.original.clockIn}
                  selectedForWorkId={row.original._id}
                  onChange={() => {
                    fetchData();
                  }}
                  defaultTime={row.original.start}
                  type="in"
                />
              );
            },
          },
          {
            Header: "Start",
            accessor: "startTime",
            filterable: false,
          },
          {
            Header: "End",
            accessor: "endTime",
            filterable: false,
          },
          {
            Header: "Clock Out",
            accessor: "clockOut",
            filterable: false,
            Cell: (row) => {
              return (
                <ClockInCell
                  clockTime={row.original.clockOut}
                  selectedForWorkId={row.original._id}
                  defaultTime={row.original.end}
                  onChange={() => {
                    fetchData();
                  }}
                  type="out"
                />
              );
            },
          },
          {
            Header: "Total Hours",
            accessor: "totalHours",
            filterable: false,
          },
          {
            Header: "Last Approval Update By",
            accessor: "lastApprovalUpdate",
            filterable: false,
          },
          // {
          //   Header: "Last Active (App)",
          //   accessor: "lastAppActive",
          // },
          {
            Header: "Reg Approval",
            accessor: "supervisorApproval",
            filterable: false,
            sortable: false,
            width: 140,
            Cell: (row) => {
              return (
                <SupervisorApprovalCheckbox
                  id={row.original._id}
                  supervisorApproval={row.original.supervisorApproval}
                  isOT={false}
                />
              );
            },
          },
          {
            Header: "Excess Hours Approval",
            accessor: "supervisorApprovalOT",
            filterable: false,
            sortable: false,
            width: 140,
            Cell: (row) => {
              return (
                <SupervisorApprovalCheckbox
                  id={row.original._id}
                  supervisorApproval={row.original.supervisorApproval}
                  supervisorApprovalOT={row.original.supervisorApprovalOT}
                  isOT={true}
                />
              );
            },
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
            width: 90,
          },
        ]}
        manual
        loading={loadingTable}
        defaultPageSize={50}
        showPaginationTop
        showPaginationBottom
        sorted={sorted}
        pages={pages}
        onFetchData={(state, instance) => {
          let searchParams = {
            page: state.page + 1,
            size: state.pageSize,
            field: state.sorted.length ? state.sorted[0].id : "status",
            sort: state.sorted.length
              ? state.sorted[0].desc
                ? "desc"
                : "asc"
              : "asc",
            filter: state.filtered,
          };
          setReactTableState(searchParams);
          clearTimeout(timer);
          setTimer(
            setTimeout(() => {
              // setLoadingTable(true);
              fetchData(searchParams);
            }, 1000)
          );
        }}
        className="-striped -highlight primary-pagination"
      />
    );
  };

  const _renderEditTabs = () => {
    let shiftFilterList = editTabs;
    return (
      <Nav
        className="nav-pills-primary nav-pills-icons justify-content-center"
        pills
        role="tablist"
      >
        {shiftFilterList.map((shift, i) => {
          return (
            <NavItem key={i}>
              <NavLink
                data-toggle="tab"
                role="tablist"
                // className={selectedShiftFilter === shift.name ? "active" : ""}
              >
                {shift.name === "P.O" ? (
                  <>
                    <div style={{ width: 90 }}>
                      <Select
                        // className="react-select primary"
                        // classNamePrefix="react-select"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        name={shift.name}
                        placeholder={shift.name}
                        isDisabled
                      />
                    </div>
                  </>
                ) : (
                  <div style={{ width: 100 }}>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name={shift.name}
                      value={requiredWorkers[shift.name]}
                      isDisabled={
                        shifts[shift.name] !== 1 ||
                        !moment(new Date(filter.startDate)).isBetween(
                          todayTomorrow().start,
                          todayTomorrow().end
                        )
                      }
                      onChange={(value) => {
                        // console.log(value);
                        let temp = requiredWorkers;
                        requiredWorkers[shift.name] = value;
                        setRequiredWorkers({ ...temp });
                        updateRequired(shift.name, value.value);
                      }}
                      options={requiredWorkerOptions}
                      placeholder={shift.name.split("")[0]}
                    />
                  </div>
                )}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    );
  };

  const updateRequired = async (option, value) => {
    const businessId =
      business.profile?.business?.id ||
      (business.profile?.length && business.profile[0]?.business?.id);
    if (businessId) {
      let timeRange;
      switch (option) {
        case "Morning":
          timeRange = morningTimeRange(filter.startDate);
          break;
        case "Afternoon":
          timeRange = afternoonTimeRange(filter.startDate);
          break;
        case "Night":
          timeRange = nightTimeRange(filter.startDate);
          break;
        case "All":
          timeRange = allDayRange(filter.startDate);
          break;
        default:
          timeRange = null;
      }
      if (timeRange) {
        const data = {
          from: timeRange.start,
          till: timeRange.end,
        };
        data.business = businessId;
        data.noRequiredWorkers = parseInt(value);
        api()
          .patch("/business/required", data)
          .then((res) => {
            // console.log(res);
            if (businessId) loadAllShifts(businessId, filter.startDate);
            setAlert(
              <SweetAlert
                success
                title="Required people updates"
                onConfirm={hideAlert}
              />
            );
          })
          .catch((err) => {
            console.log(err);
            setAlert(
              <SweetAlert
                error
                title={`An error occured! ${err.message}`}
                onConfirm={hideAlert}
              />
            );
          });
      }
    }
  };

  return (
    <div>
      {isLoading && <LoadingOverlay />}
      <Card className="no-transition">
        <CardHeader>
          <Row></Row>
        </CardHeader>
        <CardBody>
          <Row className="mt-1 justify-content-between">
            <Col>
              <CustomCalendar
                inputProps={{
                  placeholder: "Date Picker Here",
                }}
                initialDate={filter.startDate}
                onChangeDate={(date) => {
                  localStorage.setItem("selectedDate", date);
                  setFilter({ ...filter, startDate: moment(date) });
                }}
              />
            </Col>
            {/* 2023-04-05 Cleanup prior to Dufflet Meeting: Hide Daily Stats */}
            {/* <Col md="1"></Col>
              <Col md="6">
                <CustomCalendar
                  inputProps={{
                    placeholder: "Date Picker Here",
                  }}
                  initialDate={filter.startDate}
                  onChangeDate={(date) => {
                    setFilter({ ...filter, startDate: moment(date) });
                  }}
                />
              </Col>
              <Col md="2"></Col>
              <Col md="2">
                <Button onClick={() => setDailyStatModal(true)}>
                  Daily Stats <Equalizer />
                </Button>
              </Col>
              <Col md="1"></Col> */}
          </Row>
          <div
            className="d-flex justify-content-center"
            style={{ paddingTop: 20 }}
          >
            <Row>{_renderShiftTab()}</Row>
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ paddingTop: 20 }}
          >
            <Row style={{ textAlign: "center" }}>
              {" "}
              All workers must be approved to receive hours. Total hours will
              not show unless approved by a supervisor. The Total Hours column
              automatically subtracts 30 minutes for lunch.
              <br />
              <br />
              To approve regular hours, please tick the Reg Approval checkbox.
              Reg Approval will use the later time of the scheduled shift start
              or clock in time, and the earlier time of the scheduled shift end
              or clock out time. If one or more of the clock in or out times are
              missing, the system will substitute the scheduled hours for the
              missing time.
              <br />
              <br />
              {/* Renamed on Dufflet request 2023-06-07 - Helen */}
              To approve excess hours beyond the scheduled end time, please tick
              the Excess Hours Approval checkbox. Ticking Excess Hours Approval
              will also tick the Reg Approval box. Excess Hours will use the
              later time of the scheduled shift start or clock in time, and the
              clock out time. This box requires both clock in and out times.
            </Row>
          </div>
          {/* 2023-04-05 Cleanup prior to Dufflet Meeting: Hide required number (non-functioning) */}
          {/* <div
            className="d-flex justify-content-center"
            style={{ paddingTop: 20 }}
          >
            <Row>{_renderEditTabs()}</Row>
          </div> */}
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-plain card-subcategories">
                <CardBody>{_renderShiftTable()}</CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {alert}
      {dailyStatModal && (
        <DailyStatsModal
          handleCloseModal={() => {
            setDailyStatModal(false);
          }}
          openModal={dailyStatModal}
        />
      )}
    </div>
  );
};

export default LiveSelectedUsers;
