import UserProfile from "views/AllUsers/UserProfile";
import LiveSelectedUsers from "views/LiveSelectedUsers/LiveSelectedUsers.jsx";
import LiveBusiness from "views/LiveBusiness/LiveBusiness";
import AllLiveShiftWorkers from "views/LiveBusiness/AllLiveShiftWorkers";

import CreateNewBusinessStaff from "views/AllBusinessStaff/CreateNewStaff.jsx";
import BusinessStaffProfile from "views/AllBusinessStaff/StaffProfile.jsx";
import AllBusinessStaff from "views/AllBusinessStaff/AllStaff.jsx";

import WeeklyExport from "views/WeeklyExport/WeeklyExport";

export const routes = [
  // {
  //   path: "/live-shifts",
  //   icon: "nc-icon nc-tv-2",
  //   name: "Live Shifts",
  //   component: LiveShifts,
  //   layout: "/admin",
  //   hidden: false,
  // },
  {
    path: "/live-business",
    name: "Live Business",
    icon: "nc-icon nc-bank",
    layout: "/admin",
    component: LiveBusiness,
    hidden: false,
    access: ["BusinessHr"],
  },
  {
    path: "/weekly-export",
    name: "Weekly Export",
    icon: "nc-icon nc-cloud-download-93",
    layout: "/admin",
    component: WeeklyExport,
    hidden: false,
    access: ["BusinessHr"],
  },
  {
    path: "/all-live-shift-workers",
    name: "All Live Shift Workers",
    mini: "All",
    layout: "/admin",
    component: AllLiveShiftWorkers,
    hidden: true,
    access: ["BusinessHr"],
  },
  {
    path: "/live-selected-users",
    name: "Live Employees",
    icon: "nc-icon nc-tv-2",
    layout: "/admin",
    component: LiveSelectedUsers,
    hidden: false,
    access: ["BusinessShiftSupervisor", "BusinessStaff"],
  },
  {
    path: "/user-profile/:id",
    name: "Profile",
    mini: "Usr",
    component: UserProfile,
    layout: "/admin",
    hidden: true,
    access: ["BusinessShiftSupervisor", "BusinessStaff"],
  },

  {
    collapse: true,
    name: "Business Staff",
    icon: "nc-icon nc-briefcase-24",
    state: "businessesCollapse",
    access: ["BusinessHr"],
    views: [
      // 2023-04-05 Cleanup prior to Dufflet Meeting: Hide Create (Permissions Issues)
      // {
      //   path: "/create-business-staff",
      //   name: "Create New Business Staff",
      //   mini: "View",
      //   component: CreateNewBusinessStaff,
      //   layout: "/admin",
      //   hidden: false,
      //   access: ["BusinessHr"],
      // },
      {
        path: "/business-staff-profile/:id",
        name: "Business Staff Profile",
        mini: "View",
        component: BusinessStaffProfile,
        layout: "/admin",
        hidden: true,
        access: ["BusinessHr"],
      },
      {
        path: "/all-business-staff",
        name: "All Business Staff",
        mini: "View",
        component: AllBusinessStaff,
        layout: "/admin",
        hidden: false,
        access: ["BusinessHr"],
      },
    ],
  },
];

export default routes;
