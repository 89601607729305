import { Modal } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import api from "components/API/api";
import RenderAvailability from "components/Availability/RenderAvailability.jsx";
import { validateEmail } from "components/Utilities/utilities.js";
import moment from "moment";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import MaskedInput from "react-text-mask";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  DocumentStatus,
  DocumentType,
  provinceOptions,
  workStatusOptions,
} from "./allUserUtilities";
import Images from "./UserProfile/Images";
import UserTrainingHistory from "./UserProfile/UserTrainingHistory";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //setting states for profile data
      permanentDeleteText: "",
      userNotFound: false,
      firstName: "",
      lastName: "",
      dob: "",
      age: "",
      gender: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      sin: "",
      documents: "",
      // sinRegistrationNumber: "",
      sinExpiry: null,
      workStatus: null,
      verified: false,
      qualifications: [],
      originalTrainingStatus: {},
      workerPin: [],
      legallyWork: "",
      availability: [
        this.DAY,
        { ...this.DAY }, //fix: using same this.day will point to same memory for the object
        { ...this.DAY }, //so mutating one day will cause to change all
        { ...this.DAY },
        { ...this.DAY },
        { ...this.DAY },
        { ...this.DAY },
      ],
      emergencyFirstName: "",
      emergencyLastName: "",
      emergencyPhone: "",
      emergencyRelationship: "",
      documents: "",

      //data
      qualificationsList: [],

      //filling in user history tables
      chequeNotPickedUp: [],
      chequePickedUp: [],
      changeLog: [],
      changeLogData: null,
      commentHistory: [],
      addComment: "",
      errorAddComment: "",

      //misc states
      docId: "",
      hdId: "",
      isLoading: false,
      pageTabs: "editProfile",
      showWorkPermit: false,
      showStudentVisa: false,
      trainingChange: "",
      hasTrainingHistory: null,

      //modals
      openDeleteModal: false,
      openDataModal: false,
      openPermanentDeleteModal: false,
      //error handling
      errorFirstName: "",
      errorLastName: "",
      errorPinNumber: "",
      errorBadgeNumber: "",
      errorAvailability: "",
      errorGender: "",
      errorDob: "",
      errorAge: "",
      errorPhone: "",
      errorEmail: "",
      errorAddress: "",
      errorCity: "",
      errorProvince: "",
      errorPostalCode: "",
      errorSin: "",
      errorSinRegistrationNumber: "",
      errorSinExpiry: "",
      errorQualifications: "",
      errorLegallyWork: "",
      errorWorkStatus: "",
      errorEmergencyFirstName: "",
      errorEmergencyLastName: "",
      errorEmergencyPhone: "",
      errorEmergencyRelationship: "",

      //images!
      images: [],
      imagesUrl: {},
      // fetched: { user: false, qualification: false, business: false },
      userFetched: false,
      qualificationFetched: false,
      businessFetched: false,
      businesses: [],
      socket: null,
    };
  }

  componentDidMount() {
    this.loadUser();
    this.loadQualificationsList();
    this.loadBusiness();
    if (this.props.location.search.includes("tab=chat")) {
      this.setState({ pageTabs: "chat" });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.pageTabs !== this.state.pageTabs &&
      this.state.pageTabs === "comments"
    ) {
      this.loadCommentHistory();
    }

    // if (prevState.pageTabs !== this.state.pageTabs && this.state.pageTabs === "chat") {
    //   this.setState({ socket: io(ENDPOINT) });
    // } else
    // if (prevState.pageTabs !== this.state.pageTabs && this.state.pageTabs !== "chat") {
    //   if (this.state.socket) {
    //     this.state.socket.disconnect();
    //     this.state.socket.off();
    //     this.setState({ socket: null });
    //   }
    // }
    if (
      prevState.userFetched !== this.state.userFetched ||
      prevState.qualificationFetched !== this.state.qualificationFetched ||
      prevState.businessFetched !== this.state.businessFetched
    ) {
      if (
        this.state.businessFetched === true &&
        this.state.qualificationFetched === true &&
        this.state.userFetched === true
      ) {
        this.setState({
          qualifications: this.state.qualifications.map((q) => {
            return this.state.qualificationsList.find((_q) => _q.value === q);
          }),
          workerPin: this.state.workerPin
            .map((w) => {
              let business = this.state.businesses.find(
                (b) => b._id === w.business
              );
              w.businessName = business && business.name;
              return w;
            })
            .filter((w) => w.businessName),
        });
      }
    }
  }

  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <div style={{ display: "flex" }}>
        <Spinner
          color="primary"
          style={{ margin: "300px auto", width: "3rem", height: "3rem" }}
        />
      </div>
    );
  };

  loadQualificationsList = () => {
    api()
      .get("/qualification")
      .then((res) => {
        const data = res.data?.qualifications;
        let tempArray = [];
        data.map((q) => {
          tempArray.push({
            label: q.name,
            value: q._id,
          });
        });
        this.setState({
          qualificationFetched: true,
          qualificationsList: tempArray,
          // fetched: { ...this.state.fetched, qualification: true },
        });
      })
      .catch((err) => console.log(err));
  };

  loadUser = async () => {
    this.setState({ isLoading: true });
    console.log("this.props.match.params.id", this.props.match.params.id)
    try {
      const { data } = await api().get(
        `/users/worker/${this.props.match.params.id}`
      );
      const user = data;
console.log("user", user)
      this.setState({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        gender:
          user.gender === "F"
            ? { label: "Female", value: "F" }
            : { label: "Male", value: "M" } || "",
        email: user.email || "",
        phone: user.phone || "",
        address: user.address || "",
        city: user.city || "",
        province: { value: user.province, label: user.province } || "",
        postalCode: user.postalCode || "",
        sin: user.sin || "",
        // sinRegistrationNumber:
        //   user.documents?.find((d) => d.type === DocumentType.sinApplicationProof)
        //     ?.documentNumber || "",
        workStatus: workStatusOptions.find((s) => s.value === user.workStatus),
        verified: user?.verified ? true : false,
        dob: moment(user.dob).format("YYYY-MM-DD") || "",
        age: moment().diff(user.dob, "years") || "",
        emergencyFirstName: user.emergency[0]?.firstName || "",
        emergencyLastName: user.emergency[0]?.lastName || "",
        emergencyPhone: user.emergency[0]?.phone || "",
        emergencyRelationship: user.emergency[0]?.relationship || "",
        emergencyFirstName2: user.emergency[1]?.firstName || "",
        emergencyLastName2: user.emergency[1]?.lastName || "",
        emergencyPhone2: user.emergency[1]?.phone || "",
        emergencyRelationship2: user.emergency[1]?.relationship || "",
        qualifications: data.qualifications,
        duplicated: data.duplicated,
        workerPin: data.workerPin,
        hdId: data.hdId,
        suspendedFor: data.suspendedFor,
        suspendedDate: data.suspendedDate,
        totalWarning: data.totalWarning,
        sinExpiry: data?.sinExpiry
          ? moment(data.sinExpiry).format("YYYY-MM-DD")
          : "",
        documents: data.documents || [],
      });

      if (user.legallyWork) {
        this.setState({ legallyWork: { label: "Yes", value: true } });
      } else {
        this.setState({ legallyWork: { label: "No", value: true } });
      }
      // this.setState({ showWorkPermit: user.legallyWork == true });
      // this.setState({ showStudentVisa: user.legallyWork != true });

      let availability = user.availability;
      this.setState({
        userFetched: true,
        availability: availability,
        isLoading: false,
        // fetched: { ...this.state.fetched, user: true },
      });
    } catch (e) {
      console.log(e)
      this.setState({ isLoading: false, userNotFound: true });
    }
  };

  loadBusiness = async () => {
    try {
      const { data } = await api().get("/business/list");
      this.setState({
        businesses: data,
        //  fetched: { ...this.state.fetched, business: true } }
        businessFetched: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  RenderDataActions = (data) => (
    <div className="actions-center">
      <Button
        onClick={() => {
          this.handleOpenWorkerDataModal(data);
        }}
        color="info"
        size="md"
        className="btn-link btn-icon"
      >
        <Visibility style={{ marginLeft: -1 }} />
      </Button>
    </div>
  );

  workerDataModal = () => {
    let changeLogData = this.state.changeLogData;
    console.log(changeLogData);
    let workStatus = changeLogData
      ? workStatusOptions.find((s) => s.value === changeLogData.workStatus)
      : null;
    console.log(workStatus);
    return (
      <Modal
        open={this.state.openDataModal}
        onClose={this.handleCloseWorkerDataModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {changeLogData && (
          <FormGroup
            style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}
          >
            <div style={{ padding: 15 }}>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  margin: 20,
                }}
              >
                <h6>
                  {changeLogData.firstName} {changeLogData.lastName}
                </h6>
              </div>
              <div>
                <ul>
                  <Row>
                    <Col md="4" style={{ fontWeight: 550, marginTop: 4 }}>
                      Email:{" "}
                    </Col>
                    <Col md="8">{changeLogData.email}</Col>
                  </Row>
                  <Row>
                    <Col md="4" style={{ fontWeight: 550, marginTop: 4 }}>
                      Phone:{" "}
                    </Col>
                    <Col md="8">{changeLogData.phone}</Col>
                  </Row>
                  <Row>
                    <Col md="4" style={{ fontWeight: 550, marginTop: 4 }}>
                      Sin:{" "}
                    </Col>
                    <Col md="8">{changeLogData.sin}</Col>
                  </Row>
                  <Row>
                    <Col md="4" style={{ fontWeight: 550, marginTop: 4 }}>
                      Legally Work:{" "}
                    </Col>
                    <Col md="8">
                      {changeLogData.legallyWork ? "true" : "false"}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" style={{ fontWeight: 550, marginTop: 4 }}>
                      Work Status:{" "}
                    </Col>
                    <Col md="8">
                      {workStatus ? workStatus.label : "not found"}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" style={{ fontWeight: 550, marginTop: 4 }}>
                      Date of Birth:{" "}
                    </Col>
                    <Col md="8">
                      {moment(changeLogData.dob).format("YYYY-MM-DD")}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" style={{ fontWeight: 550, marginTop: 4 }}>
                      Gender:{" "}
                    </Col>
                    <Col md="8">
                      {changeLogData.gender
                        ? "F"
                          ? "Female"
                          : "Male"
                        : "not Found"}
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4" style={{ fontWeight: 550, marginTop: 4 }}>
                      Address:{" "}
                    </Col>
                    <Col md="8">{changeLogData.address}</Col>
                  </Row>
                  <Row>
                    <Col md="4" style={{ fontWeight: 550, marginTop: 4 }}>
                      City:{" "}
                    </Col>
                    <Col md="8">{changeLogData.city}</Col>
                  </Row>
                  <Row>
                    <Col md="4" style={{ fontWeight: 550, marginTop: 4 }}>
                      Province:{" "}
                    </Col>
                    <Col md="8">{changeLogData.province}</Col>
                  </Row>
                  <Row>
                    <Col md="4" style={{ fontWeight: 550, marginTop: 4 }}>
                      Postal Code:{" "}
                    </Col>
                    <Col md="8">{changeLogData.postalCode}</Col>
                  </Row>
                </ul>
              </div>

              <div style={{ justifyContent: "center", display: "flex" }}>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.handleCloseWorkerDataModal();
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </FormGroup>
        )}
      </Modal>
    );
  };

  handleOpenWorkerDataModal = (data) => {
    console.log(data);
    this.setState({
      changeLogData: data,
      openDataModal: true,
    });
  };

  handleCloseWorkerDataModal = () => {
    this.setState({
      changeLogData: null,
      openDataModal: false,
    });
  };
  //Renders the nav for each of the tabs.
  renderNavTabs = () => {
    let tabList = [
      { name: "Edit Profile", id: "editProfile" },
      // { name: "Shift History", id: "shiftHistory" },
      { name: "Training History", id: "trainingHistory" },
      // { name: "Pay History", id: "payHistory" },
      // { name: "Driver Ride History", id: "rideHistory" },
      // { name: "Cheque Request", id: "chequeRequest" },
      // { name: "Images and Files", id: "images" },
      // { name: "Comments", id: "comments" },
      // { name: "Chat", id: "chat" },
      // { name: "Change Log", id: "changeLog" },
    ];

    return (
      <>
        {tabList.map((tab) => {
          // if (tab.id === "images") {
          //   return (
          //       <NavItem>
          //         <NavLink
          //           data-toggle="tab"
          //           role="tablist"
          //           className={this.state.pageTabs === tab.id ? "active" : ""}
          //           onClick={() => {
          //             this.setState({ pageTabs: tab.id });
          //           }}
          //         >
          //           <i className="now-ui-icons ui-2_settings-90" />
          //           {tab.name}
          //         </NavLink>
          //       </NavItem>

          //   );
          // }
          return (
            <NavItem>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={this.state.pageTabs === tab.id ? "active" : ""}
                onClick={() => {
                  this.setState({ pageTabs: tab.id });
                }}
              >
                <i className="now-ui-icons ui-2_settings-90" />
                {tab.name}
              </NavLink>
            </NavItem>
          );
        })}
      </>
    );
  };

  update = async (imageId, userId, updates) => {
    console.log(updates);
    await api().patch("/users/documents", {
      updates,
      userId: userId,
      documentId: imageId,
    });
  };

  insert = async (userId, document) => {
    const { data } = await api().post("/users/documents", {
      document,
      userId: userId,
    });
    return data._id;
  };

  render() {
    return (
      <div className="content">
        {this.state.userNotFound ? (
          <div>Error 404: No user found</div>
        ) : (
          <Row>
            <Col>
              <Card className="no-transition">
                <CardBody>
                  <Nav
                    className="nav-pills-primary nav-pills-icons justify-content-center"
                    pills
                    role="tablist"
                  >
                    {this.renderNavTabs()}
                  </Nav>
                  {this.state.isLoading ? (
                    this.loadingOverlay()
                  ) : (
                    <TabContent
                      className="tab-space tab-subcategories"
                      activeTab={this.state.pageTabs}
                    >
                      {/* <TabPane tabId="shiftHistory">
                        {this.state.pageTabs === "shiftHistory" ? (
                          <UserShiftHistory
                            userId={this.props.match.params.id}
                            agency={
                              this.context.agency.agency &&
                              this.context.agency.agency._id
                            }
                          />
                        ) : null}
                      </TabPane> */}
                      <TabPane tabId="trainingHistory">
                        <UserTrainingHistory
                          userId={this.props.match.params.id}
                          // agency={
                          //   this.context.agency.agency &&
                          //   this.context.agency.agency._id
                          // }
                        />
                      </TabPane>

                      <TabPane tabId="editProfile">
                        <CardBody>
                          <Form>
                            <Row>
                              <Col md="10"></Col>
                              {/* <Col md="2">
                                <Button
                                  style={{ marginTop: -4 }}
                                  onClick={() => {
                                    this.setState({ verified: !this.state.verified });
                                  }}
                                  color={this.state.verified ? "success" : "danger"}
                                  size="md"
                                >
                                  {this.state.verified ? "Verified" : "Not verified"}
                                </Button>
                              </Col> */}
                            </Row>
                            <Row>
                              <Col className="pr-1" md="4">
                                <FormGroup>
                                  <Label>First Name</Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.firstName}`}
                                    onChange={(e) =>
                                      this.setState({
                                        firstName: e.target.value,
                                      })
                                    }
                                    placeholder="First Name"
                                    type="text"
                                  />
                                  <FormText color="danger" tag="span">
                                    {this.state.errorFirstName}
                                  </FormText>
                                </FormGroup>
                              </Col>
                              <Col className="px-1" md="4">
                                <FormGroup>
                                  <Label>Last Name</Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.lastName}`}
                                    onChange={(e) =>
                                      this.setState({
                                        lastName: e.target.value,
                                      })
                                    }
                                    placeholder="Last Name"
                                    type="text"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorLastName}
                                </FormText>
                              </Col>
                              <Col className="px-1" md="4">
                                <FormGroup>
                                  <Label>Extension</Label>
                                  <Input
                                    disabled={true}
                                    value={this.state.duplicated}
                                    onChange={(e) =>
                                      this.setState({
                                        duplicated: e.target.value,
                                      })
                                    }
                                    placeholder="-"
                                    type="text"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="pr-1" md="4">
                                <FormGroup>
                                  <Label>Date of Birth</Label>
                                  <Input
                                    disabled={true}

                                    //HIDING FOR PRIVACY
                                    //value={this.state.dob}
                                    // onChange={(e) =>
                                    //   this.setState({
                                    //     dob: e.target.value,
                                    //     age: moment().diff(
                                    //       e.target.value,
                                    //       "years"
                                    //     ),
                                    //   })
                                    // }
                                    placeholder="Date of Birth"
                                    type="date"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorDob}
                                </FormText>
                              </Col>
                              <Col className="px-1" md="4">
                                <FormGroup>
                                  <Label>Age</Label>
                                  <Input
                                    disabled={true}
                                    readOnly
                                    value={`${this.state.age}`}
                                    placeholder="Age"
                                    type="text"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorAge}
                                </FormText>
                              </Col>
                              <Col className="pl-1" md="4">
                                <FormGroup>
                                  <Label>Gender</Label>
                                  <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Gender"
                                    name="singleSelect"
                                    closeMenuOnSelect={false}
                                    value={this.state.gender}
                                    onChange={(value) => {
                                      this.setState({
                                        gender: value,
                                      });
                                    }}
                                    options={[
                                      { label: "Female", value: "F" },
                                      { label: "Male", value: "M" },
                                    ]}
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorGender}
                                </FormText>
                              </Col>
                            </Row>
                            {/* HIDING FOR PRIVACY
                            <Row>
                              <Col className="pr-1" md="4">
                                <FormGroup>
                                  <Label>Phone Number</Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.phone}`}
                                    onChange={(e) =>
                                      this.setState({ phone: e.target.value })
                                    }
                                    maxLength="10"
                                    placeholder="Phone Number"
                                    type="text"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorPhone}
                                </FormText>
                              </Col>
                              <Col className="pl-1" md="4">
                                <FormGroup>
                                  <Label>Street Address</Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.address}`}
                                    onChange={(e) =>
                                      this.setState({ address: e.target.value })
                                    }
                                    placeholder="Home Address"
                                    type="text"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorAddress}
                                </FormText>
                              </Col>
                              <Col className="pl-1" md="4">
                                <FormGroup>
                                  <Label>Email address</Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.email}`}
                                    onChange={(e) =>
                                      this.setState({ email: e.target.value })
                                    }
                                    placeholder="Email"
                                    type="email"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorEmail}
                                </FormText>
                              </Col>
                            </Row> */}
                            <Row>
                              <Col className="pr-1" md="4">
                                <FormGroup>
                                  <Label>City</Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.city}`}
                                    onChange={(e) =>
                                      this.setState({ city: e.target.value })
                                    }
                                    placeholder="City"
                                    type="text"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorCity}
                                </FormText>
                              </Col>
                              <Col className="px-1" md="4">
                                <FormGroup>
                                  <Label>Province</Label>
                                  <Select
                                    isDisabled
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={this.state.province}
                                    onChange={(value) =>
                                      this.setState({ province: value })
                                    }
                                    options={provinceOptions}
                                    placeholder="Select a province"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorProvince}
                                </FormText>
                              </Col>
                              <Col className="pl-1" md="4">
                                <FormGroup>
                                  <Label>Postal Code</Label>
                                  <MaskedInput
                                    disabled
                                    mask={[
                                      /[A-Za-z]/,
                                      /\d/,
                                      /[A-Za-z]/,
                                      /\d/,
                                      /[A-Za-z]/,
                                      /\d/,
                                    ]}
                                    id="postalCode"
                                    className="form-control"
                                    autoComplete="off"
                                    maxLength="8"
                                    placeholder="A1B2C3"
                                    onChange={(e) => {
                                      this.setState({
                                        postalCode: e.target.value,
                                      });
                                    }}
                                    value={this.state.postalCode || ""}
                                  />
                                  <FormText color="danger" tag="span">
                                    {this.state.errorPostalCode}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="pr-1" md="4">
                                <FormGroup>
                                  <Label>Social Insurance Number</Label>
                                  <Input
                                    value={`${this.state.sin}`}
                                    disabled
                                    onChange={async (e) => {
                                      let input = e.target.value;
                                      this.setState({ sin: input });
                                      if (input === 10) {
                                        const tempDocs = this.state.documents;
                                        const search = tempDocs.find(
                                          (d) => d.type === DocumentType.sin
                                        );
                                        if (search) {
                                          await this.update(
                                            search._id,
                                            this.props.match.params.id,
                                            {
                                              documentNumber: input,
                                            }
                                          );
                                          tempDocs.map((d) => {
                                            if (d._id === search._id) {
                                              d.documentNumber = input;
                                            }
                                          });
                                        } else {
                                          const data = {
                                            type: DocumentType.sin,
                                            fileName: "3.jpeg",
                                            status: DocumentStatus.pending,
                                            documentNumber: input,
                                          };
                                          const documentId = await this.insert(
                                            this.props.match.params.id,
                                            data
                                          );
                                          data._id = documentId;
                                          tempDocs.push(data);
                                        }
                                        this.setState({ documents: tempDocs });
                                      }
                                    }}
                                    maxLength="9"
                                    placeholder="Social Insurance Number"
                                    type="text"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorSin}
                                </FormText>
                              </Col>
                              {this.state.sin?.startsWith("9") ||
                              this.state.sin?.trim() === "000000000" ? (
                                <Col className="px-1" md="4">
                                  <FormGroup>
                                    <Label>*Sin Expiry Date</Label>
                                    <Input
                                      disabled={true}
                                      type="date"
                                      autoComplete="off"
                                      maxLength="3"
                                      onChange={async (e) => {
                                        let input = e.target.value;
                                        this.setState({ sinExpiry: input });
                                        if (input) {
                                          const tempDocs = this.state.documents;
                                          const search = tempDocs.find(
                                            (d) => d.type === DocumentType.sin
                                          );
                                          if (search) {
                                            await this.update(
                                              search._id,
                                              this.props.match.params.id,
                                              {
                                                expires: input,
                                              }
                                            );
                                            tempDocs.map((d) => {
                                              if (d._id === search._id) {
                                                d.expires = input;
                                              }
                                            });
                                          } else {
                                            const data = {
                                              type: DocumentType.sin,
                                              fileName: "3.jpeg",
                                              status: DocumentStatus.pending,
                                              expires: input,
                                            };
                                            const documentId =
                                              await this.insert(
                                                this.props.match.params.id,
                                                data
                                              );
                                            data._id = documentId;
                                            tempDocs.push(data);
                                          }
                                          this.setState({
                                            documents: tempDocs,
                                          });
                                        }
                                      }}
                                      value={this.state.sinExpiry}
                                      id="sinExpiry"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorSinExpiry}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              ) : null}

                              <Col className="px-1" md="4">
                                <FormGroup>
                                  <Label>Qualifications List</Label>
                                  <Select
                                    isDisabled
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    placeholder=" Qualifications"
                                    name="multipleSelect"
                                    closeMenuOnSelect={false}
                                    isMulti
                                    value={this.state.qualifications}
                                    onChange={(value) => {
                                      this.setState({
                                        qualifications: value,
                                      });
                                    }}
                                    options={this.state.qualificationsList}
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorQualifications}
                                </FormText>
                              </Col>
                              {/* <Col className="px-1" md="4">
                                <FormGroup>
                                  <Label>Legal to work in canada ?</Label>
                                  <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    placeholder="Legal status"
                                    name="singleSelect"
                                    closeMenuOnSelect={false}
                                    value={this.state.legallyWork}
                                    onChange={(value) => {
                                      this.setState({
                                        legallyWork: value,
                                      });
                                    }}
                                    options={[
                                      { label: "Yes", value: true },
                                      { label: "No", value: false },
                                    ]}
                                  />
                                  <FormText color="danger" tag="span">
                                    {this.state.errorLegallyWork}
                                  </FormText>
                                </FormGroup>
                              </Col> */}
                              <Col className="pr-1" md="4">
                                <Label>Work Status</Label>
                                <FormGroup>
                                  <Select
                                    isDisabled
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    closeMenuOnSelect={false}
                                    id="workStatus"
                                    value={this.state.workStatus}
                                    onChange={(value) => {
                                      this.setState({ workStatus: value });
                                      if (value.value === 1)
                                        this.setState({
                                          alert: (
                                            <SweetAlert
                                              info
                                              title={
                                                "Please make sure work permit image and work permit expiry exists in Images"
                                              }
                                              onConfirm={this.hideAlert}
                                            />
                                          ),
                                        });
                                      if (value.value === 2)
                                        this.setState({
                                          alert: (
                                            <SweetAlert
                                              info
                                              title={
                                                "Please make sure study permit image and study permit expiry exists in Images"
                                              }
                                              onConfirm={this.hideAlert}
                                            />
                                          ),
                                        });
                                    }}
                                    options={workStatusOptions}
                                    placeholder="Select a work status"
                                  />
                                  <FormText color="danger" tag="span">
                                    {this.state.errorWorkStatus}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="pr-1" md="6">
                                <FormGroup>
                                  <Label>Availability</Label>
                                  <RenderAvailability
                                    availability={
                                      this.state.availability.length
                                        ? this.state.availability
                                        : void 0
                                    }
                                    onChange={(newAvailability) => {
                                      this.setState({
                                        availability: newAvailability,
                                      });
                                    }}
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorAvailability}
                                </FormText>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="pr-1" md="6">
                                <FormGroup>
                                  <Label>Emergency Contact 1: First Name</Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.emergencyFirstName}`}
                                    onChange={(e) =>
                                      this.setState({
                                        emergencyFirstName: e.target.value,
                                      })
                                    }
                                    placeholder="Emergency Contact 1: First Name"
                                    type="text"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorEmergencyFirstName}
                                </FormText>
                              </Col>
                              <Col className="pl-1" md="6">
                                <FormGroup>
                                  <Label>Emergency Contact 1: Last Name</Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.emergencyLastName}`}
                                    onChange={(e) =>
                                      this.setState({
                                        emergencyLastName: e.target.value,
                                      })
                                    }
                                    placeholder="Emergency Contact 1: Last Name"
                                    type="text"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorEmergencyLastName}
                                </FormText>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="pr-1" md="6">
                                <FormGroup>
                                  <Label>
                                    Emergency Contact 1: Phone Number
                                  </Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.emergencyPhone}`}
                                    onChange={(e) =>
                                      this.setState({
                                        emergencyPhone: e.target.value,
                                      })
                                    }
                                    maxLength="10"
                                    placeholder="Emergency Contact 1: Phone Number"
                                    type="text"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorEmergencyPhone}
                                </FormText>
                              </Col>
                              <Col className="pl-1" md="6">
                                <FormGroup>
                                  <Label>
                                    Emergency Contact 1: Relationship
                                  </Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.emergencyRelationship}`}
                                    onChange={(e) =>
                                      this.setState({
                                        emergencyRelationship: e.target.value,
                                      })
                                    }
                                    placeholder="Emergency Contact 1: Relationship"
                                    type="text"
                                  />
                                </FormGroup>
                                <FormText color="danger" tag="span">
                                  {this.state.errorEmergencyRelationship}
                                </FormText>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="pr-1" md="6">
                                <FormGroup>
                                  <Label>Emergency Contact 2: First Name</Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.emergencyFirstName2}`}
                                    onChange={(e) =>
                                      this.setState({
                                        emergencyFirstName2: e.target.value,
                                      })
                                    }
                                    placeholder="Emergency Contact 2: First Name"
                                    type="text"
                                  />
                                </FormGroup>
                              </Col>
                              <Col className="pl-1" md="6">
                                <FormGroup>
                                  <Label>
                                    Emergency Contact 2: Last Name (Optional)
                                  </Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.emergencyLastName2}`}
                                    onChange={(e) =>
                                      this.setState({
                                        emergencyLastName2: e.target.value,
                                      })
                                    }
                                    placeholder="Emergency Contact 2: Last Name"
                                    type="text"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="pr-1" md="6">
                                <FormGroup>
                                  <Label>
                                    Emergency Contact 2: Phone Number (Optional)
                                  </Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.emergencyPhone2}`}
                                    onChange={(e) =>
                                      this.setState({
                                        emergencyPhone2: e.target.value,
                                      })
                                    }
                                    maxLength="10"
                                    placeholder="Emergency Contact 2: Phone Number"
                                    type="text"
                                  />
                                </FormGroup>
                              </Col>
                              <Col className="pl-1" md="6">
                                <FormGroup>
                                  <Label>
                                    Emergency Contact 2: Relationship (Optional)
                                  </Label>
                                  <Input
                                    disabled={true}
                                    value={`${this.state.emergencyRelationship2}`}
                                    onChange={(e) =>
                                      this.setState({
                                        emergencyRelationship2: e.target.value,
                                      })
                                    }
                                    placeholder="Emergency Contact 2: Relationship"
                                    type="text"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </TabPane>
                      <TabPane tabId="images">
                        <CardBody>
                          {this.state.pageTabs === "images" && (
                            <Images documents={this.state.documents} />
                          )}
                        </CardBody>
                      </TabPane>
                    </TabContent>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {this.state.alert}
      </div>
    );
  }
}

export default UserProfile;
