import { Visibility } from "@material-ui/icons";
// Bring out of class to manage it's own state.
import api from "components/API/api";
import { useBusiness } from "context/BusinessContext";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { startOfDay, startOfNextDay } from "utils/dateUtils.js";
import { statusEnum } from "utils/statusVariables.js";
import ClockInCell from "../LiveSelectedUsers/ClockInCell";
import ShiftFunctionCell from "../LiveSelectedUsers/ShiftFunctionCell";
import { CSVLink } from "react-csv";

const AllSelectedUsers = (props) => {
  const businessContext = useBusiness();
  const [workersData, setWorkersData] = useState([]); //actual workers data fetched

  const [data, setData] = useState([]); // data just to show in table based on options
  const [shiftId, setShiftId] = useState(null);
  const [shift, setShift] = useState({});
  const [business, setBusiness] = useState({});
  const [counters, setCounters] = useState({
    confirmed: 0,
    backup: 0,
    available: 0,
  });
  const [date, setDate] = useState(null);
  const [loadingTable, setLoadingTable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [pageTabs, setPageTabs] = useState("manuallySelected");
  const [businessList, setBusinessList] = useState([]);

  const [fetchedBusiness, setFetchedBusiness] = useState(false);
  const [fetchedUserData, setFetchedUserData] = useState(false);

  const [exportData, setExportData] = useState([]);
  const [exportHeaders, setExportHeaders] = useState([
    {
      label: "HD ID",
      key: "hdId",
    },
    {
      label: "First Name",
      key: "firstName",
    },
    {
      label: "Last Name",
      key: "displayLastName",
    },
    {
      label: "Function",
      key: "shiftFunctionName",
    },
    {
      label: "Pay Rate",
      key: "shiftFunctionPayRate",
    },
    {
      label: "Clocked In",
      key: "clockInTime",
    },
    {
      label: "Clocked Out",
      key: "clockOutTime",
    },
    {
      label: "Total Hours",
      key: "totalHours",
    },
    {
      label: "Status",
      key: "statusName",
    },
    {
      label: "Last Approval Update By",
      key: "lastApprovalUpdate",
    },
    {
      label: "Approval",
      key: "supervisorApproval",
    },
    {
      label: "Excess Hours Approval", // Renamed on Dufflet request 2023-06-07 - Helen
      key: "supervisorApprovalOT",
    },
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    const shift = urlParams.get("shift");
    const date = urlParams.get("date");

    setShiftId(shift);
    setDate(date);
    if (typeof shift === "string" && typeof date === "string") {
      fetchUsers(shift, date);
      fetchShift(shift, date);
    } else {
      props.history.push(`/admin/live-business`);
    }
  }, []);

  useEffect(() => {
    //after both business and userData are loaded use the business to add it to worker's pin
    if (fetchedUserData && fetchedBusiness) {
      setFetchedUserData(false);
      setFetchedBusiness(false);
      if (data.length > 0 && businessList.length > 0) {
        setData((tempUserData) => {
          return tempUserData.map((u) => {
            u.lastAppLoginTime = u.lastAppLogin
              ? moment(u.lastAppLogin).format("MMM Do YYYY, h:mm a")
              : null;
            if (u.workerPin && u.workerPin.length > 0) {
              u.pin = u.workerPin.map((w) => w.pin || "-").join(", ");
              u.workerPin = u.workerPin
                .map((w) => {
                  // console.log(w);
                  // w.businessId = businessList.find((b) => b._id === w.business).business;
                  // w.business = businessList.find((b) => b._id === w.business).name;
                  let business = businessList.find((b) => b._id === w.business);
                  w.businessName = business && business.name;
                  return w;
                })
                .filter((w) => w.businessName);
            }
            return {
              ...u,
            };
          });
        });
      }
    }
  }, [fetchedUserData, fetchedBusiness]);

  const RenderActionButtons = (s) => {
    return (
      <div className="actions-center">
        <Button
          tag={Link}
          to={`/admin/user-profile/${s._id}`}
          onClick={() => {
            props.history.push(`user-profile/${s._id}`);
          }}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  const fetchUsers = async (_id = shiftId, _date = date) => {
    setLoadingTable(true);
    try {
      api()
        .get(`selected-for-work/users/${_id}`, {
          params: {
            start: startOfDay(new Date(_date)),
            end: startOfNextDay(new Date(_date)),
          },
        })
        .then(({ data }) => {
          let newData = data.map((s) => {
            const clockIn = s.check?.in;
            const clockOut = s.check?.out;
            const supervisorApproval = s.supervisorApproval ? true : false;
            const supervisorApprovalOT = s.supervisorApprovalOT ? true : false;

            const defaultClockIn =
              s.check?.in?.time > s.start ? s.check.in.time : s.start;
            const defaultClockOut =
              s.check?.out?.time < s.end ? s.check.out.time : s.end;

            // always use the LATER of scheduled start and clock in. They never start early
            const defaultClockInToSubtract = s.check?.in?.time
              ? moment(defaultClockIn).add(30, "minutes")
              : moment(s.start).add(30, "minutes");

            let totalHours = "-";

            if (supervisorApprovalOT === true) {
              // overtime approval (more than scheduled end), use clock out
              totalHours =
                clockIn && clockOut
                  ? moment(clockOut.time)
                      .diff(defaultClockInToSubtract, "hours", true)
                      .toFixed(2)
                  : "-";
            } else if (supervisorApproval === true) {
              // regular approval, use default times or later clock in/earlier clock out if exists

              let regStart = s.check?.in?.time
                ? defaultClockInToSubtract
                : moment(s.start).add(30, "minutes");
              let regEnd = s.check?.out?.time ? defaultClockOut : s.end;

              totalHours = moment(regEnd)
                .diff(regStart, "hours", true)
                .toFixed(2);
            }

            return {
              ...s,
              displayLastName:
                s.displayLastName || s.duplicated
                  ? s.lastName + "-" + s.duplicated
                  : s.lastName,
              comment: s.comment ? s.comment.note : null,
              lastAppLoginTime: s.lastAppLogin
                ? moment(s.lastAppLogin).format("MMM Do YYYY, h:mm a")
                : null,
              vaccineStatus:
                s.vaccineStatus === 2
                  ? "Fully Vaccinated"
                  : s.vaccineStatus === 1
                  ? "1st dose"
                  : "No Vaccine",
              actions: RenderActionButtons(s),
              shiftFunctionName: s.shiftFunction?.name
                ? s.shiftFunction.name
                : "-",
              shiftFunctionPayRate: s.shiftFunction?.payRate
                ? "$ " + s.shiftFunction.payRate
                : "-",
              clockIn,
              clockOut,
              totalHours,

              startTime: moment(s.start).format("h:mm a"),
              endTime: moment(s.end).format("h:mm a"),
              supervisorApproval: supervisorApproval,
              lastApprovalUpdate: s.supervisorName ? s.supervisorName : "",
            };
          });

          let tempCSVExportData = [];
          newData.map((worker) => {
            console.log("worker to csv", worker);

            worker.clockInTime = worker.clockIn?.time
              ? moment(worker.clockIn?.time).format("hh:mm A")
              : "-";
            worker.clockOutTime = worker.clockOut?.time
              ? moment(worker.clockOut?.time).format("hh:mm A")
              : "-";

            if (worker.status === 1) {
              worker.statusName = "Confirmed";
              tempCSVExportData.push(worker);
            } else if (worker.status === 2) {
              worker.statusName = "Backup";
              tempCSVExportData.push(worker);
            }
          });

          setExportData(tempCSVExportData);

          setData(newData);
          setWorkersData(newData);
          setDataByFilter(pageTabs, newData);
          setLoadingTable(false);

          const counters = {
            confirmed: newData.filter(
              (data) => data.status === statusEnum.selectedForWork.selected
            )?.length,
            backup: newData.filter(
              (data) => data.status === statusEnum.selectedForWork.Backup
            )?.length,
            available: newData.filter(
              (data) => data.status === statusEnum.selectedForWork.Applied
            )?.length,
            startTime: data.start,
            endTime: data.end,
          };

          setCounters(counters);
          setFetchedUserData(true);
        })
        .catch((e) => {
          setLoadingTable(false);
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const updateSupervisorApproval = (
    selectedForWorkId,
    oldSupervisorApproval,
    oldSupervisorApprovalOT,
    isOT
  ) => {
    const newSupervisorApprovalId = businessContext?.business?.user?.id;
    const newSupervisorApprovalName =
      businessContext?.business?.user?.firstName;

    console.log(
      businessContext,
      newSupervisorApprovalId,
      newSupervisorApprovalName
    );

    setIsLoading(true);

    let newSupervisorApproval = oldSupervisorApproval;
    let newSupervisorApprovalOT = oldSupervisorApprovalOT;

    // if OT is updating to true, set regular approval to true. Otherwise, just toggle
    if (isOT === true) {
      if (!oldSupervisorApprovalOT === true) {
        newSupervisorApproval = true;
      }
      newSupervisorApprovalOT = !oldSupervisorApprovalOT;
    } else {
      newSupervisorApproval = !oldSupervisorApproval;
    }

    api()
      .patch(`/selected-for-work/supervisor-approval/${selectedForWorkId}`, {
        supervisorApprovalUpdate: newSupervisorApproval,
        supervisorApprovalOTUpdate: newSupervisorApprovalOT,
        supervisorIdUpdate: newSupervisorApprovalId,
        supervisorNameUpdate: newSupervisorApprovalName,
      })
      .then(() => {
        fetchUsers();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const SupervisorApprovalCheckbox = (rowData) => {
    return (
      <div className="actions-center">
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              onChange={() => {
                updateSupervisorApproval(
                  rowData.selectedForWorkId,
                  rowData.supervisorApproval,
                  rowData.supervisorApprovalOT,
                  rowData.isOT
                );
              }}
              checked={
                rowData.isOT
                  ? rowData.supervisorApprovalOT
                  : rowData.supervisorApproval
              }
              id={"supervisorApprovalCheckbox"}
            />
            <span className="form-check-sign" />
            {rowData.isOT ? "Excess Hours" : "Reg Hours"}
          </Label>
        </FormGroup>
      </div>
    );
  };

  const fetchShift = async (_id, date) => {
    try {
      api()
        .get(`shift/${_id}`, { params: { date: new Date(date) } })
        .then(({ data }) => {
          setShift(data.shift);
          setBusiness(data.shift.business);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const setDataByFilter = (type = pageTabs, _data = workersData) => {
    console.log(workersData);
    if (type === "allSelected") {
      setData(_data);
    } else if (type === "manuallySelected") {
      setData(
        _data.filter(
          (data) => data.status === statusEnum.selectedForWork.selected
        )
      );
    } else if (type === "backup") {
      setData(
        _data.filter(
          (data) => data.status === statusEnum.selectedForWork.Backup
        )
      );
    } else if (type === "preSelected") {
      setData(
        _data.filter(
          (data) => data.status === statusEnum.selectedForWork.Applied
        )
      );
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card className="no-transition">
            <CardHeader>
              <CardTitle>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col md="9">
                    <h4>All Users at: {(business.name, business.address)}</h4>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <CSVLink
                        data={exportData}
                        headers={exportHeaders}
                        filename={`${business.name}-${moment(date).format(
                          "MMM-Do-YYYY"
                        )}-${
                          shift.start && moment(shift.start).format("hh:mm A")
                        }-${
                          shift.end && moment(shift.end).format("hh:mm A")
                        }.csv`}
                      >
                        <Button color="default" outline>
                          Export All
                        </Button>
                      </CSVLink>
                    </FormGroup>
                  </Col>
                </Row>
              </CardTitle>
              <Row>
                <Col>
                  <h6>
                    Shift Start:
                    {shift.start && moment(shift.start).format(" hh:mm A")}
                  </h6>
                  <h6>
                    Shift End:
                    {shift.end && moment(shift.end).format(" hh:mm A")}
                  </h6>
                  <h6>
                    Required workers:{" "}
                    {shift.noRequiredWorkers
                      ? shift.noRequiredWorkers
                      : " Not specified"}
                  </h6>
                </Col>
              </Row>
              <Nav
                className="nav-pills-primary nav-pills-icons justify-content-center"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tablist"
                    className={pageTabs === "manuallySelected" ? "active" : ""}
                    onClick={() => {
                      setPageTabs("manuallySelected");
                      setDataByFilter("manuallySelected");
                    }}
                  >
                    <i className="now-ui-icons ui-2_settings-90" />
                    Selected Workers ({counters.confirmed})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    data-toggle="tab"
                    role="tablist"
                    className={pageTabs === "backup" ? "active" : ""}
                    onClick={() => {
                      setPageTabs("backup");
                      setDataByFilter("backup");
                    }}
                  >
                    <i className="now-ui-icons ui-2_settings-90" />
                    Backup Workers ({counters.backup})
                  </NavLink>
                </NavItem>
              </Nav>
              <div
                className="d-flex justify-content-center"
                style={{ padding: 20, paddingBottom: 0 }}
              >
                <Row style={{ textAlign: "center" }}>
                  {" "}
                  All workers must be approved to receive hours. Total hours
                  will not show unless approved by a supervisor. The Total Hours
                  column automatically subtracts 30 minutes for lunch.
                  <br />
                  <br />
                  To approve regular hours, please tick the Reg Approval
                  checkbox. Reg Approval will use the later time of the
                  scheduled shift start or clock in time, and the earlier time
                  of the scheduled shift end or clock out time. If one or more
                  of the clock in or out times are missing, the system will
                  substitute the scheduled hours for the missing time.
                  <br />
                  <br />
                  {/* Renamed on Dufflet request 2023-06-07 - Helen */}
                  To approve excess hours beyond the scheduled end time, please
                  tick the Excess Hours Approval checkbox. Ticking Excess Hours
                  Approval will also tick the Reg Approval box. Excess Hours
                  will use the later time of the scheduled shift start or clock
                  in time, and the clock out time. This box requires both clock
                  in and out times.
                </Row>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "hdId",
                    accessor: "hdId",
                    maxWidth: 100,
                  },
                  {
                    Header: "First Name",
                    accessor: "firstName",
                  },
                  {
                    Header: "Last Name",
                    accessor: "displayLastName",
                  },
                  {
                    Header: "Function",
                    accessor: "shiftFunctionName",
                    Cell: (row) => {
                      return (
                        <ShiftFunctionCell
                          shiftFunction={row.original.shiftFunction}
                          selectedForWorkId={row.original.selectedForWorkId}
                          shiftFunctions={shift.shiftFunctions}
                          onChange={() => {
                            fetchUsers();
                          }}
                          shiftId={shiftId}
                        />
                      );
                    },
                  },
                  {
                    Header: "Pay Rate",
                    accessor: "shiftFunctionPayRate",
                  },
                  // {
                  //   Header: "Pin",
                  //   accessor: "pin",
                  //   maxWidth: 80,
                  //   // Cell: (row) => {
                  //   //   return (
                  //   //     <PinCell
                  //   //       value={row.value}
                  //   //       workerPin={row.original.workerPin}
                  //   //       // _id={row.original.workerPin && row.original.workerPin._id}
                  //   //       onChange={() => {
                  //   //         fetchUsers();
                  //   //       }}
                  //   //       workerId={row.original._id}
                  //   //       businessList={businessList}
                  //   //       agencyId={agencyContext.agency.agency._id}
                  //   //     />
                  //   //   );
                  //   // },
                  // },
                  {
                    Header: "Clocked In",
                    accessor: "clockIn",
                    filterable: false,
                    Cell: (row) => {
                      return (
                        <ClockInCell
                          clockTime={row.original.clockIn}
                          selectedForWorkId={row.original.selectedForWorkId}
                          onChange={() => {
                            fetchUsers();
                          }}
                          defaultTime={row.original.start}
                          type="in"
                        />
                      );
                    },
                  },
                  {
                    Header: "Scheduled Start",
                    accessor: "startTime",
                  },
                  {
                    Header: "Scheduled End",
                    accessor: "endTime",
                  },
                  {
                    Header: "Clocked Out",
                    accessor: "clockOut",
                    filterable: false,
                    Cell: (row) => {
                      return (
                        <ClockInCell
                          clockTime={row.original.clockOut}
                          selectedForWorkId={row.original.selectedForWorkId}
                          defaultTime={row.original.end}
                          onChange={() => {
                            fetchUsers();
                          }}
                          type="out"
                        />
                      );
                    },
                  },
                  {
                    Header: "Total Hours",
                    accessor: "totalHours",
                    filterable: false,
                  },
                  {
                    Header: "Last Approval Update By",
                    accessor: "lastApprovalUpdate",
                    filterable: false,
                  },
                  {
                    Header: "Reg Approval",
                    accessor: "supervisorApproval",
                    filterable: false,
                    sortable: false,
                    width: 140,
                    Cell: (row) => {
                      return (
                        <SupervisorApprovalCheckbox
                          selectedForWorkId={row.original.selectedForWorkId}
                          supervisorApproval={row.original.supervisorApproval}
                          isOT={false}
                        />
                      );
                    },
                  },
                  {
                    Header: "Excess Hours Approval", // Renamed on Dufflet request 2023-06-07 - Helen
                    accessor: "supervisorApprovalOT",
                    filterable: false,
                    sortable: false,
                    width: 140,
                    Cell: (row) => {
                      return (
                        <SupervisorApprovalCheckbox
                          selectedForWorkId={row.original.selectedForWorkId}
                          supervisorApproval={row.original.supervisorApproval}
                          supervisorApprovalOT={
                            row.original.supervisorApprovalOT
                          }
                          isOT={true}
                        />
                      );
                    },
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    width: 90,
                  },
                ]}
                data={data}
                defaultPageSize={10}
                loading={loadingTable}
                showPaginationTop
                showPaginationBottom
                className="-striped -highlight primary-pagination"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AllSelectedUsers;
