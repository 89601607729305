import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Container } from "reactstrap";

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  const history = useHistory();

  return (
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("assets/img/sections/david-marcu.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title">HD Group of Companies</h1>
              <br />
              <Button
                className="btn-round mr-1"
                color="neutral"
                onClick={() => {
                  history.push("/auth/login");
                }}
              >
                Supervisor Portal
              </Button>
              <Button
                className="btn-round"
                color="neutral"
                type="button"
                outline
                onClick={() => {
                  history.push("/qr/login");
                }}
              >
                QR Clock In
              </Button>
            </div>
          </Container>
        </div>
      </div>
  );
}

export default LandingPageHeader;
