import React, { useEffect, useState } from "react";
import ExcelExport from "./ExcelExport";

export const customExcelData = function ({
  headers,
  shifts,
  businessName,
  date, // if daily export
  day, // if daily export
  weekly, // if weekly export
  weekending, // if weekly export
}) {
  let emptyCell = { value: "" };
  let backgroundStyle = {
    fill: { fgColor: { rgb: "FFB0B0B0" } },
    font: { color: { rgb: "FFFFFFFF" }, bold: true },
  };
  let errorStyle = {
    fill: { fgColor: { rgb: "FF0000" } },
    font: { color: { rgb: "FFFFFFFF" }, bold: true },
  };
  let emptyWithStyle = { value: "", style: backgroundStyle };

  let row1 = [
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { value: businessName },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
  ];
  let row2 = [
    { ...emptyCell },
    { ...emptyCell },
    { value: "DATE:" },
    { value: date },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
  ];
  let row3 = [
    { ...emptyCell },
    { ...emptyCell },
    { value: "DAY:" },
    { value: day },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
  ];
  let row4 = [
    { ...emptyCell },
    { ...emptyCell },
    { value: "Week Ending:" },
    { value: weekending },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
  ];

  let titleTable = [];
  let headerCols = [];
  let fileTitle;
  if (weekly === true) {
    fileTitle =
      "Dufflet-Pastries-Week-Ending-" + weekending + "-All-Shifts-Export";
    titleTable = [row1, row4];
    headerCols = [
      { width: { wpx: 80 }, title: "" }, //pixels width
      { width: { wpx: 50 }, title: "" }, //pixels width
      { width: { wch: 15 }, title: "" }, //char width
      { width: { wch: 15 }, title: "" },
      { width: { wpx: 140 }, title: "" }, //pixels width
      { width: { wpx: 60 }, title: "" },
      { width: { wpx: 70 }, title: "" },
      { width: { wpx: 70 }, title: "" },
      { width: { wpx: 70 }, title: "" },
      { width: { wpx: 70 }, title: "" },
      { width: { wpx: 50 }, title: "" },
      { width: { wpx: 80 }, title: "" },
    ];
  } else {
    fileTitle = "Dufflet-Pastries-" + day + "-All-Shifts-Export";
    titleTable = [row1, row2, row3];
    headerCols = [
      { width: { wpx: 50 }, title: "" }, //pixels width
      { width: { wch: 15 }, title: "" }, //char width
      { width: { wch: 15 }, title: "" },
      { width: { wpx: 140 }, title: "" }, //pixels width
      { width: { wpx: 60 }, title: "" },
      { width: { wpx: 70 }, title: "" },
      { width: { wpx: 70 }, title: "" },
      { width: { wpx: 70 }, title: "" },
      { width: { wpx: 70 }, title: "" },
      { width: { wpx: 50 }, title: "" },
      { width: { wpx: 80 }, title: "" },
    ];
  }

  //section 2 headers

  //section 3 data for shifts
  let headersArray = headers.map((h) => h.key);
  const shiftTable = [
    headers.map((h) => ({
      value: h.label,
      style: backgroundStyle,
    })),
    ...shifts.map((_row, i) => {
      _row.productionNo = i + 1;
      let dataRow = [];
      for (let headerCol of headersArray) {
        if (_row.clockOutTime !== "Total" && Number(_row.totalHours) < 1) {
          dataRow.push({
            value: _row[headerCol] || "",
            style: errorStyle,
          });
        } else {
          dataRow.push({
            value: _row[headerCol] || "",
          });
        }
      }

      return dataRow;
    }),
  ];

  let allData = [...titleTable, ...shiftTable];

  return {
    columns: headerCols,
    data: allData,
    title: fileTitle,
  };
};

export default function CustomExportDaily({
  excelData,
  download,
  setDownload,
  fileName,
}) {
  const [customDownload, setCustomDownload] = useState(false);

  useEffect(() => {
    if (download) {
      setCustomDownload(download);
    }
  }, [download]);

  return download && excelData.length ? (
    <ExcelExport
      download={customDownload}
      dataset={excelData}
      setDownload={() => {
        setCustomDownload(false);
        setDownload(false);
      }}
      filename={fileName}
    />
  ) : null;
}
