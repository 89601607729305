/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
// import logoUrl from "assets/img/scout-logo1.png";
import logo from "assets/img/logo-long.png";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageError: false,
      // logoUrl: "",
    };
  }
  componentDidMount() {
    // this.setState({ logoUrl: this.props.logo });
  }

  render() {
    return (
      <Navbar
        className={classnames("navbar-absolute fixed-top")}
        // style = {{backgroundColor: 'rgb(0, 74, 140)'}}
        style={{ backgroundColor: "rgb(30, 30, 30)" }}
      >
        <Container>
          <div className="navbar-wrapper">
            <NavbarBrand onClick={(e) => e.preventDefault()}>
              <img
                style={{ maxWidth: "150px", maxHeight: "80px" }}
                src={logo}
                alt={this.props.companyName || "Logo"}
                // onError={() => {
                //   if (!this.state.imageError) {
                //     this.setState({
                //       imageError: true,
                //       logoUrl: "http://hdgroup.ca/wp-content/themes/hdgroup/images/logo.png",
                //     });
                //   }
                // }}
              ></img>
            </NavbarBrand>
            {/* <h5>{this.props.companyName}</h5> */}
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default AuthNavbar;
