import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import ReactDatetime from "react-datetime";
import moment from "moment";
import PropTypes from "prop-types";

export default function CustomCalendar({
  style,
  initialDate,
  onChangeDate,
  myDate,
  textColor = "#555",
}) {
  const [date, setDate] = useState(
    moment(initialDate).day("Saturday").set("hour", 23).set("minute", 59) ||
      moment().day("Saturday").set("hour", 23).set("minute", 59)
  );

  const changeDate = function (date, change) {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + change);
    return newDate;
  };

  useEffect(() => {
    if (myDate && new Date(myDate).getTime() !== new Date(date).getTime()) {
      setDate(moment(myDate));
    }
  }, [myDate]);

  useEffect(() => {
    if (new Date(date).getTime() !== new Date(initialDate).getTime()) {
      let newDate = moment(date);
      newDate.set({ hour: 1 });

      if (!onChangeDate) return;
      onChangeDate(new Date(newDate));
    }
  }, [date]);

  return (
    <div className="d-flex justify-content-between" style={{ ...style }}>
      <div
        style={{
          cursor: "pointer",
          marginTop: "0.5rem",
          color: textColor,
        }}
        onClick={() => {
          setDate(changeDate(date, -7));
        }}
      >
        <ChevronLeft />
        Previous Week
      </div>

      <p style={{ cursor: "pointer" }}>
        Week Start:
        <ReactDatetime
          inputProps={{
            placeholder: "Week Start",
          }}
          timeFormat={false}
          value={moment(date).day("Sunday")}
          onChange={(date) => {
            setDate(moment(date).day("Sunday").set("hour", 5).set("minute", 1));
          }}
        />
      </p>
      <p style={{ cursor: "pointer" }}>
        Week End:
        <ReactDatetime
          inputProps={{
            placeholder: "Week End",
          }}
          timeFormat={false}
          value={moment(date).day("Saturday")}
          onChange={(date) => {
            setDate(
              moment(date).day("Saturday").set("hour", 23).set("minute", 59)
            );
          }}
        />
      </p>
      <div
        flex
        row
        style={{
          cursor: "pointer",
          marginTop: "0.5rem",
          color: textColor,
        }}
        onClick={() => setDate(changeDate(date, 7))}
      >
        Next Week
        <ChevronRight />
      </div>
    </div>
  );
}

CustomCalendar.propTypes = {
  style: PropTypes.object,
  initialDate: PropTypes.object,
  onChangeDate: PropTypes.func,
  disablePreviousDate: PropTypes.bool,
  myDate: PropTypes.object, //for controlled input
};
