import api from "components/API/api";
import CustomCalendarForWeek from "components/Calendar/CustomCalendarForWeek";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import { useBusiness } from "context/BusinessContext";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
} from "reactstrap";
import { startOfDay, startOfNextDay } from "utils/dateUtils.js";
import { CSVLink } from "react-csv";
import CustomExportExcel, {
  customExcelData,
} from "components/Excel/CustomExportExcel";
import exportPDF from "./ExportPDF.js";

const originBusiness = {
  name: "1980384 Ontario Inc.",
  address: "1268 Concession Road 7",
  city: "Palgrave",
  province: "Ontario",
  postalCode: "L0N 1P0",
  country: "Canada",
  HST: "",
};

const businessInvoiceAddress = {
  name: "Dufflet Pastries",
  address: "166 Norseman Street",
  city: "Toronto",
  province: "Ontario",
  postalCode: "M8Z 2R4",
  country: "Canada",
};

const invoiceNumber = "";

const ExportBusinessModule = function ({ business, currentDate }) {
  const [exportShiftData, setExportShiftData] = useState([]);
  const [exportShiftHeaders, setExportShiftHeaders] = useState([
    {
      label: "Date",
      key: "shiftDate",
    },
    {
      label: "HD ID",
      key: "hdId",
    },
    {
      label: "First Name",
      key: "firstName",
    },
    {
      label: "Last Name",
      key: "displayLastName",
    },
    {
      label: "Function",
      key: "shiftFunctionName",
    },
    {
      label: "Pay Rate",
      key: "shiftFunctionPayRate",
    },
    {
      label: "Clocked In",
      key: "clockInTime",
    },
    {
      label: "Shift Start",
      key: "shiftStart",
    },
    {
      label: "Shift End",
      key: "shiftEnd",
    },
    {
      label: "Clocked Out",
      key: "clockOutTime",
    },
    {
      label: "Total Hours",
      key: "hours",
    },
    {
      label: "Status",
      key: "statusName",
    },
    {
      label: "Last Approval Update By",
      key: "lastApprovalUpdate",
    },
    {
      label: "Reg Approval",
      key: "supervisorApproval",
    },
    {
      label: "Excess Hours Approval", // Renamed on Dufflet request 2023-06-07 - Helen
      key: "supervisorApprovalOT",
    },
  ]);
  const [exportWorkersData, setExportWorkersData] = useState([]);
  const [exportWorkersHeaders, setExportWorkersHeaders] = useState([
    {
      label: "HD ID",
      key: "hdId",
    },
    {
      label: "First Name",
      key: "firstName",
    },
    {
      label: "Last Name",
      key: "displayLastName",
    },
    {
      label: "Function",
      key: "shiftFunctionName",
    },
    {
      label: "Pay Rate",
      key: "shiftFunctionPayRate",
    },
    {
      label: "Total Hours",
      key: "totalHours",
    },
    {
      label: "Regular Hours",
      key: "regularHours",
    },
    {
      label: "Overtime Hours",
      key: "overtimeHours",
    },
  ]);
  const [exportInvoiceData, setExportInvoiceData] = useState([]);
  const [exportInvoiceHeaders, setExportInvoiceHeaders] = useState([
    {
      label: "Function",
      key: "shiftFunctionName",
    },
    {
      label: "Total Hours",
      key: "totalHours",
    },
    {
      label: "Base Rate",
      key: "shiftFunctionPayRate",
    },
    {
      label: "Reg Rate (26%)",
      key: "shiftFunctionRegularRate",
    },
    {
      label: "Reg Hours",
      key: "regularHours",
    },
    {
      label: "Reg Subtotal",
      key: "regularSubtotal",
    },
    {
      label: "OT Rate (26%, 1.5x)",
      key: "shiftFunctionOvertimeRate",
    },
    {
      label: "OT Hours",
      key: "overtimeHours",
    },
    {
      label: "OT Subtotal",
      key: "overtimeSubtotal",
    },
    {
      label: "Subtotal",
      key: "subtotal",
    },
    {
      label: "Tax (13%)",
      key: "tax",
    },
    {
      label: "Total",
      key: "total",
    },
  ]);
  const [exportInvoiceTotalSummary, setExportInvoiceTotalSummary] = useState(
    []
  );

  const [showStyles, setShowStyles] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [displayExport, setDisplayExport] = useState(false);

  const [excelData, setExcelData] = useState([]);
  const [download, setDownload] = useState(false);

  useEffect(() => {
    setDisplayExport(false);
    setExportLoading(false);
  }, [currentDate]);

  // after shift information is exported, calculate invoice details
  // sum up all hours per worker. If hours > 44, extra is considered overtime (1.5x rate)
  // sum up all hours per shift function. Assume workers never change function mid week. Separate OT from reg hours
  const calculateHours = async (businessShiftData) => {
    // Get worker weekly totals. Get all workers who worked and their total work hours for the week.
    const workerWeeklyTotals = await businessShiftData.reduce(
      (accumulator, cur) => {
        let hdId = cur.hdId,
          found = accumulator.find((elem) => {
            return elem.hdId === hdId;
          });
        if (found) found.totalHours += cur.totalHours;
        else if (!cur.firstName) {
          console.log("total hours", cur.hours);
        } else accumulator.push(cur);
        return accumulator;
      },
      []
    );
    // Get overtime hours
    await workerWeeklyTotals.map((worker) => {
      if (worker.totalHours > 44) {
        worker.overtimeHours = worker.totalHours - 44;
        worker.regularHours = 44;
      } else {
        worker.regularHours = worker.totalHours;
        worker.overtimeHours = 0;
      }
    });

    // Get weekly totals per shift function. This info will be part of the invoice
    const functionWeeklyTotals = await workerWeeklyTotals.reduce(
      (accumulator, cur) => {
        let shiftFunctionName = cur.shiftFunctionName,
          shiftFunctionPayRate = cur.shiftFunctionPayRate,
          found = accumulator.find((elem) => {
            return (
              elem.shiftFunctionName === shiftFunctionName &&
              elem.shiftFunctionPayRate === shiftFunctionPayRate
            );
          });

        if (found) {
          found.totalHours += cur.totalHours;
          found.overtimeHours += cur.overtimeHours;
        } else
          accumulator.push({
            totalHours: cur.totalHours,
            overtimeHours: cur.overtimeHours,
            shiftFunctionName: cur.shiftFunctionName,
            shiftFunctionPayRate: cur.shiftFunctionPayRate,
          });
        return accumulator;
      },
      []
    );

    // Get invoice information. Calculate based on functionWeeklyTotals
    const invoiceWeeklyTotals = await functionWeeklyTotals.map((f) => {
      const taxPercentage = 0.13;
      const agencyMultiplier = 1.26;

      const shiftFunctionRegularRate =
        f.shiftFunctionPayRate * agencyMultiplier;
      const shiftFunctionOvertimeRate =
        f.shiftFunctionPayRate * agencyMultiplier * 1.5;
      const regularHours = f.totalHours - f.overtimeHours;
      const regularSubtotal = regularHours * shiftFunctionRegularRate;
      const overtimeSubtotal = f.overtimeHours * shiftFunctionOvertimeRate;

      const subtotal = regularSubtotal + overtimeSubtotal;
      const tax = taxPercentage * subtotal;
      const total = subtotal + tax;

      return {
        ...f,
        shiftFunctionRegularRate,
        shiftFunctionOvertimeRate,
        regularHours,
        regularSubtotal,
        overtimeSubtotal,
        subtotal,
        tax,
        total,
      };
    });

    // Sort by worker name
    workerWeeklyTotals.sort((a, b) => a.firstName.localeCompare(b.firstName));

    // Sort by function name
    invoiceWeeklyTotals.sort((a, b) =>
      a.shiftFunctionName.localeCompare(b.shiftFunctionName)
    );

    // Calculate some values across all functions this week
    const invoiceTotal = invoiceWeeklyTotals.reduce(
      (n, { total }) => n + Number(total),
      0
    );
    const invoiceSubtotal = invoiceWeeklyTotals.reduce(
      (n, { subtotal }) => n + Number(subtotal),
      0
    );
    const invoiceTax = invoiceWeeklyTotals.reduce(
      (n, { tax }) => n + Number(tax),
      0
    );
    const invoiceOvertimeHours = invoiceWeeklyTotals.reduce(
      (n, { overtimeHours }) => n + Number(overtimeHours),
      0
    );
    const invoiceOvertimeSubtotal = invoiceWeeklyTotals.reduce(
      (n, { overtimeSubtotal }) => n + Number(overtimeSubtotal),
      0
    );
    const invoiceRegularHours = invoiceWeeklyTotals.reduce(
      (n, { regularHours }) => n + Number(regularHours),
      0
    );
    const invoiceRegularSubtotal = invoiceWeeklyTotals.reduce(
      (n, { regularSubtotal }) => n + Number(regularSubtotal),
      0
    );
    const invoiceTotalHours = invoiceWeeklyTotals.reduce(
      (n, { totalHours }) => n + Number(totalHours),
      0
    );

    const totalSummary = {
      shiftFunctionName: "Summary",
      totalHours: invoiceTotalHours,
      regularHours: invoiceRegularHours,
      regularSubtotal: invoiceRegularSubtotal,
      overtimeHours: invoiceOvertimeHours,
      overtimeSubtotal: invoiceOvertimeSubtotal,
      tax: invoiceTax,
      subtotal: invoiceSubtotal,
      total: invoiceTotal,
    };

    // Push the above values into the csv for export. Sort before adding this
    invoiceWeeklyTotals.push(totalSummary);

    // Filter out placeholder shift function name if no total hours
    const filteredInvoiceWeeklyTotals = invoiceWeeklyTotals.filter((sf) => {
      if (sf.totalHours == 0 && sf.shiftFunctionPayRate == 0) {
        return;
      } else {
        return sf;
      }
    });
    console.log("filteredInvoiceWeeklyTotals", filteredInvoiceWeeklyTotals);

    setExportWorkersData(workerWeeklyTotals);
    setExportInvoiceData(filteredInvoiceWeeklyTotals);
    setExportInvoiceTotalSummary(totalSummary);
  };

  // fetch users for export
  const fetchExportUsers = async (businessShiftData, _date) => {
    setExportLoading(true);
    let tempCSVExportData = [];
    businessShiftData.forEach((shift) => {
      api()
        .get(`selected-for-work/users/${shift._id}`, {
          params: {
            start: startOfDay(new Date(_date)),
            end: startOfNextDay(new Date(_date)),
          },
        })
        .then(({ data }) => {
          let newData = data.map((s) => {
            const clockIn = s.check?.in;
            const clockOut = s.check?.out;
            const supervisorApproval = s.supervisorApproval ? true : false;
            const supervisorApprovalOT = s.supervisorApprovalOT ? true : false;

            const defaultClockIn =
              s.check?.in?.time > s.start ? s.check.in.time : s.start;
            const defaultClockOut =
              s.check?.out?.time < s.end ? s.check.out.time : s.end;

            // always use the LATER of scheduled start and clock in. They never start early
            const defaultClockInToSubtract = s.check?.in?.time
              ? moment(defaultClockIn).add(30, "minutes")
              : moment(s.start).add(30, "minutes");

            let totalHours = 0;

            if (supervisorApprovalOT === true) {
              // overtime approval (more than scheduled end), use clock out
              totalHours =
                clockIn && clockOut
                  ? moment(clockOut.time)
                      .diff(defaultClockInToSubtract, "hours", true)
                      .toFixed(2)
                  : 0;
            } else if (supervisorApproval === true) {
              // regular approval, use default times or later clock in/earlier clock out if exists
              let regStart = s.check?.in?.time
                ? defaultClockInToSubtract
                : moment(s.start).add(30, "minutes");
              let regEnd = s.check?.out?.time ? defaultClockOut : s.end;

              totalHours = moment(regEnd)
                .diff(regStart, "hours", true)
                .toFixed(2);
            }
            return {
              ...s,
              shiftDate: s.start ? moment(s.start).format("YYYY-MM-DD") : "-",
              shiftStart: s.start ? moment(s.start).format("h:mm A") : "-",
              shiftEnd: s.end ? moment(s.end).format("h:mm A") : "-",
              displayLastName:
                s.displayLastName || s.duplicated
                  ? s.lastName + "-" + s.duplicated
                  : s.lastName,
              shiftFunctionName: s.shiftFunction?.name
                ? s.shiftFunction.name
                : "-",
              shiftFunctionPayRate: s.shiftFunction?.payRate
                ? Number(s.shiftFunction.payRate)
                : 0,
              overtimeHours: 0,
              clockIn,
              clockOut,
              // use hours for shift export so calculateHours() doesn't mutate it
              hours: Number(totalHours),
              totalHours: Number(totalHours),

              startTime: moment(s.start).format("h:mm a"),
              endTime: moment(s.end).format("h:mm a"),
              supervisorApproval: supervisorApproval,
              supervisorApprovalOT: supervisorApprovalOT,
              lastApprovalUpdate: s.supervisorName ? s.supervisorName : "",
            };
          });

          newData.map((worker) => {
            worker.clockInTime = worker.clockIn?.time
              ? moment(worker.clockIn?.time).format("hh:mm A")
              : "-";
            worker.clockOutTime = worker.clockOut?.time
              ? moment(worker.clockOut?.time).format("hh:mm A")
              : "-";

            if (worker.status === 1) {
              worker.statusName = "Confirmed";
              tempCSVExportData.push(worker);
            } else if (worker.status === 2) {
              worker.statusName = "Backup";
              tempCSVExportData.push(worker);
            }
          });
          setExportShiftData(tempCSVExportData);
        })
        .catch((e) => {
          console.log(e);
        });
    });

    setTimeout(() => {
      // sort data based on hdId. Goal is to group shifts per worker
      tempCSVExportData.sort((a, b) => a.hdId.localeCompare(b.hdId));

      const shiftTotalHours = tempCSVExportData.reduce(
        (n, { hours }) => n + Number(hours),
        0
      );
      tempCSVExportData.push({ hours: shiftTotalHours });
      setExportShiftData(tempCSVExportData);

      calculateHours(tempCSVExportData);
      setExportLoading(false);
    }, 15000);
  };

  return (
    <Card className="no-transition">
      <CardHeader>
        <div className="d-flex justify-content-between">
          <CardTitle tag="h6">{business.name}</CardTitle>
        </div>
      </CardHeader>
      <CardBody>
        {displayExport ? (
          <>
            {exportLoading ? (
              <FormGroup style={{ display: "flex", justifyContent: "center" }}>
                Please wait, Export is Loading. This may take up to a minute.
                Please do not navigate away from this page or change the
                selected week.
              </FormGroup>
            ) : (
              <>
                <FormGroup
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <Button
                    color="default"
                    outline
                    onClick={() => {
                      setDisplayExport(false);
                    }}
                  >
                    Cancel Export
                  </Button>
                  <CustomExportExcel
                    excelData={excelData}
                    download={download}
                    setDownload={(download) => setDownload(download)}
                    fileName={`${business.name}-Week-Ending-${moment(
                      currentDate
                    ).format("MMM-Do-YYYY")}-All-Shifts-Export.xlsx`}
                  />
                  <Button
                    color="default"
                    outline
                    onClick={() => {
                      let tempExcel = [];

                      tempExcel.push(
                        customExcelData({
                          shifts: exportShiftData,
                          currentDate,
                          headers: exportShiftHeaders,
                          businessName: business.name,
                          date: moment(currentDate).format("MMMM DD YYYY"),
                          day: moment(currentDate).format("dddd"),
                          weekly: true,
                          weekending: moment(currentDate)
                            .day("Saturday")
                            .format("MMMM DD YYYY"),
                        })
                      );
                      setExcelData(tempExcel);
                      setDownload(true);
                    }}
                  >
                    Export All Shifts (Excel)
                  </Button>
                  <CSVLink
                    data={exportShiftData}
                    headers={exportShiftHeaders}
                    filename={`${business.name}-Week-Ending-${moment(
                      currentDate
                    ).format("MMM-Do-YYYY")}-All-Shifts-Export.csv`}
                  >
                    {" "}
                    <Button color="default" outline>
                      Export All Shifts (CSV)
                    </Button>
                  </CSVLink>
                  {/* Not necessary. Hidden to remove clutter
                <CSVLink
                  data={exportWorkersData}
                  headers={exportWorkersHeaders}
                  filename={`${business.name}-Week-Ending-${moment(
                    currentDate
                  ).format("MMM-Do-YYYY")}-All-Workers-Export.csv`}
                >
                  {" "}
                  <Button color="default" outline>
                    Export Workers
                  </Button>
                </CSVLink> */}
                  {/* <CSVLink
                    data={exportInvoiceData}
                    headers={exportInvoiceHeaders}
                    filename={`${business.name}-Week-Ending-${moment(
                      currentDate
                    ).format("MMM-Do-YYYY")}-Invoice-Export.csv`}
                  >
                    {" "}
                    <Button color="default" outline>
                      Export Invoice Data
                    </Button>
                  </CSVLink>
                  <Button
                    color="default"
                    outline
                    onClick={() => {
                      exportPDF(
                        exportInvoiceData,
                        exportInvoiceTotalSummary,
                        originBusiness,
                        businessInvoiceAddress,
                        moment(currentDate).format("MMM Do YYYY"),
                        moment(currentDate).format("YYYY-MM-DD")
                      );
                    }}
                  >
                    Download Invoice
                  </Button> */}
                </FormGroup>
                {/* Fixed row width to use in ExportPDF */}
              </>
            )}
          </>
        ) : (
          <FormGroup style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="default"
              outline
              onClick={() => {
                setDisplayExport(true);
                fetchExportUsers(business.shifts, currentDate);
              }}
            >
              Prepare Export
            </Button>
          </FormGroup>
        )}
      </CardBody>
    </Card>
  );
};

const WeeklyExport = () => {
  const [businesses, setBusinesses] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const { business } = useBusiness();

  const [alert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (business?.profile) {
      console.log("profile", business?.profile);

      const businessList = business?.profile?.length
        ? business.profile?.map((p) => p?.business?._id)
        : [];
      setBusinessList(businessList);
      console.log("business list", businessList);
    }
  }, [business]);

  useEffect(() => {
    businessList?.length && loadLiveBusinesses(selectedDate);
  }, [businessList, selectedDate]);

  const loadLiveBusinesses = (date) => {
    setIsLoading(true);
    console.log("load live business start");
    businessList.length &&
      api()
        .post(`/business/all-shift-requests/`, {
          start: moment(date)
            .day("Sunday")
            .set("hour", 5)
            .set("minute", 1)
            .set("second", 1),
          end: moment(date)
            .day("Saturday")
            .set("hour", 23)
            .set("minute", 59)
            .set("second", 59),
          business: businessList,
        })
        .then(({ data }) => {
          console.log("load live business complete", data);
          setBusinesses(data);
        })
        .catch((e) => {
          setAlert({
            error: true,
            title: e.response?.data?.message || e.message,
          });
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  return (
    <div className="content">
      <Row>
        <Col>
          {isLoading && <LoadingOverlay />}
          <Card className="no-transition">
            <CardHeader>
              <CardTitle tag="h4">Weekly Invoice Export</CardTitle>
              <CustomCalendarForWeek
                inputProps={{
                  placeholder: "Date Picker Here",
                }}
                initialDate={selectedDate}
                onChangeDate={(date) => {
                  setSelectedDate(moment(date));
                  console.log("selectedDate", date);
                }}
              />
            </CardHeader>
            <CardBody>
              <div
                className="d-flex justify-content-center"
                style={{ padding: 20 }}
              >
                <Row style={{ textAlign: "center" }}>
                  Please select the week to export. This export will include all
                  approved hours for the entire week:
                  <br />
                  <br />
                  <div style={{ textAlign: "left" }}>
                    Export Shifts: Excel and CSV options. This export is a
                    weekly version of the Daily Shifts export, covering the
                    entire week instead of a single day. This export will
                    contain each shift worked this week.
                    <br />
                    <br />
                    {/* Export Workers: This export is a summary of each worker's
                    regular hours and overtime hours. The information here
                    assumes that the worker was not switching shift functions
                    midweek. If the worker has multiple shift functions this
                    week, only 1 of them will display.
                    <br />
                    <br /> */}
                    {/* Export Invoice Data: CSV only. This export is a summary of
                    the week's shift functions. Details will include the total
                    number of hours and overtime hours per shift function (ex.
                    General Labour, Dishwasher, etc), regular and overtime
                    subtotals, HST, and the invoice subtotal and invoice total.
                    <br />
                    <br />
                    Download Invoice: PDF only. Download the invoice displayed
                    below. */}
                  </div>
                </Row>
              </div>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                {businesses.length > 0 ? (
                  businesses.map((business, i) => {
                    return (
                      <Col md="12">
                        <ExportBusinessModule
                          business={business}
                          key={i}
                          index={i}
                          currentDate={selectedDate}
                          onUpdate={() => {
                            loadLiveBusinesses(selectedDate);
                          }}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <p>You have no assigned Businesses!</p>
                )}
              </Row>
            </CardBody>
          </Card>
          {alert}
        </Col>
      </Row>
    </div>
  );
};

export default WeeklyExport;
