/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

// Firebase
//import { store } from "components/Firebase/firebase.js";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.localImage;
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);

    //this.storageRef = store.ref();
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
      let regExpPng = "^[d][a][t][a][:][i][m][a][g][e][/][p][n][g]";
      let regExpJpg = "^[d][a][t][a][:][i][m][a][g][e][/][j][p][g]";
      let regExpJpeg = "^[d][a][t][a][:][i][m][a][g][e][/][j][p][e][g]";
      console.log("reader result jpeg ", reader.result.match(regExpJpeg));
      console.log("reader result jpg ", reader.result.match(regExpJpg));
      console.log("reader result png ", reader.result.match(regExpPng));

      if (
        reader.result.match(regExpPng) === null &&
        reader.result.match(regExpJpeg) === null &&
        reader.result.match(regExpJpg) === null
      ) {
        console.log("this image isnt a jpeg/jpg or png file!");
      } else {
        console.log("jpeg/jpg or png detected");
        this.props.handleImages(file, this.props.index);
      }
    };
    reader.readAsDataURL(file);
  }

  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    });
    this.refs.fileInput.value = null;
  }
  resetState = () => {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    });
  };
  render() {
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
          <img
            src={this.state.imagePreviewUrl}
            alt="..."
            style={{ cursor: "pointer" }}
            onClick={this.props.onSelect}
          />
        </div>
        <div>
          {/* {this.state.file === null ? (
            <Button className="btn-round" onClick={() => this.handleClick()}>
              {this.props.avatar ? "Add Photo" : "Select image"}
            </Button>
          ) : (
            <span>
              <Button className="btn-round" onClick={() => this.handleClick()}>
                Change
              </Button>
              {this.props.avatar ? <br /> : null}
              <Button color="danger" className="btn-round" onClick={() => this.handleRemove()}>
                <i className="fa fa-times" />
                Remove
              </Button>
            </span>
          )} */}
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default ImageUpload;
