/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Messy merge //
// styles
import "assets/css/bootstrap.min.css";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/scss/paper-dashboard.scss";
import "assets/scss/paper-kit.scss";
import api from "components/API/api";
import BusinessProvider from "context/BusinessContext";
import BusinessContext from "context/BusinessContext";
import { ModalProvider } from "context/ModalContext";
import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";
import moment from "moment";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Route, Router, Switch } from "react-router-dom";
import CreatePassword from "views/CreateAdminPassword/CreatePassword.jsx";
import Error404 from "views/pages/Error404.jsx";
import AgencyLandingPage from "views/pages/LandingPage.jsx";
import QRLogin from "views/QR/Login";
import SignIn from "views/QR/SignIn";
import { history } from "./history";

// Messy merge end//
const timeZoneFix = async () => {
  let { data } = await api().get("/server-timestamp");
  //offset to match the server time
  var offset = new Date(data.utc_timestamp).getTime() - Date.now();
  //offset to match Toronto/Canada timezone
  let currentTimeZoneUtcOffset = moment().tz(moment.tz.guess()).utcOffset(),
    hoursOffset = -currentTimeZoneUtcOffset / 60,
    minuteOffset = -currentTimeZoneUtcOffset % 60;

  //destination timeZone utc offset
  let torontoOffset = moment().tz("America/Toronto").utcOffset(),
    torontoHoursOffset = -torontoOffset / 60,
    torontoMinuteOffset = -torontoOffset % 60;

  let totalOffset =
    offset +
    hoursOffset / 60 / 60 / 1000 +
    minuteOffset / 60 / 1000 -
    torontoHoursOffset / 60 / 60 / 1000 -
    torontoMinuteOffset / 60 / 1000;
  if (Math.abs(totalOffset) > 5000) {
    //ignore if offset isn't greater than 5000ms
    moment.now = function () {
      return Date.now() + totalOffset;
    };
    return true;
  }
};

const App = (props) => {
  const [alert, setAlert] = useState(false);
  useEffect(() => {
    (async () => {
      if (process.env.NODE_ENV === "production" && (await timeZoneFix())) {
        setAlert(true);
      }
    })();
  }, []);

  return (
    <>
      <ModalProvider>
        <BusinessProvider>
          <Router history={history}>
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => <AgencyLandingPage {...props} />}
              />
              <Route
                path="/auth"
                render={(props) => <AuthLayout {...props} />}
              />
              <Route
                path="/qr/login"
                render={(props) => <QRLogin {...props} />}
              />
              <Route
                path="/qr/sign-in"
                render={(props) => <SignIn {...props} />}
              />
              <Route
                path="/admin"
                render={(props) => <AdminLayout {...props} />}
              />
              {/* 2023-04-05 Cleanup prior to Dufflet Meeting: force slug to be hd-group. All supervisor portals are associated with HD only */}
              <Route
                path="/hd-group/create-password"
                render={(props) => <CreatePassword {...props} />}
              />
              {/* <Route path="/:slug/admin/create-shift" render={(props) => <CreateShifts {...props} />} /> */}
              <Route
                path="/error"
                render={(props) => <Error404 {...props} />}
              />
              <Route render={(props) => <Error404 {...props} />} />
            </Switch>
          </Router>
        </BusinessProvider>
      </ModalProvider>
      {alert && (
        <div>
          <SweetAlert
            warning
            title={"Time Mismatch"}
            onConfirm={() => setAlert(false)}
            showConfirm={true}
          >
            Your time isn't in sync with Toronto Time. We will try to sync the
            time to current Toronto time. You might see different time as it
            will be relative. You might also have limited access. You can fix
            this issue by changing your system time to Canada/toronto and
            reloading the page.
          </SweetAlert>
        </div>
      )}
    </>
  );
};

export default App;
