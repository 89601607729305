/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useReducer } from "react";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import api from "components/API/api";
import Background from "./charles-forerunner-3fPXt37X6UQ-unsplash.jpg";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Spinner as ReactSpinner } from "reactstrap";
import { Redirect } from "react-router-dom";

// import './style.css'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      alert: null,
      isLoading: false,
      marginTop: "calc(50vh - 230px)",
      slug: "",
    };
  }

  componentDidMount = () => {
    /* 2023-04-05 Cleanup prior to Dufflet Meeting: force slug to be hd-group. All supervisor portals are associated with HD only */
    // const slug = this.props.match.params.slug.toLowerCase();
    const slug = "hd-group";
    this.setState({ slug });
  };

  buttonClick = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    try {
      await api().post("/users/request/reset-password", {
        email: this.state.email,
        slug: this.state.slug,
      });
      this.setState({ isLoading: false });
      this.setState({
        alert: (
          <ReactBSAlert
            title="Email was sent!"
            onConfirm={() => {
              this.props.history.push(`login`);
            }}
          />
        ),
      });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      this.setState({
        alert: (
          <ReactBSAlert
            error
            title="Your Login credentials are incorrect. Please try again."
            onConfirm={() => this.setState({ alert: null })}
          />
        ),
      });
    }
  };

  keyPress = (e) => {
    if (e.key === "Enter") {
      this.buttonClick();
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
      isLoading: false,
    });
  };

  windowCheck = () => {
    if (window.innerHeight > 550) {
      this.setState({ marginTop: "calc(50vh - 230px)" });
    } else {
      this.setState({ marginTop: "50px" });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    window.addEventListener("resize", this.windowCheck);

    return (
      <div className="login-page">
        {this.state.alert}

        <div
          className="full-page-background"
          style={{
            backgroundColor: "rgb(221,221,221)",
            backgroundImage: `url(${Background})`,
            zIndex: 2,
          }}
        />

        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="3">
              <Form
                style={{
                  marginTop: this.state.marginTop,
                  fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                }}
                action=""
                className="form"
                method=""
              >
                <div style={{ position: "fixed", top: "50%", left: "50%" }}>
                  {this.state.isLoading && (
                    <ReactSpinner
                      className="text-center"
                      type="border"
                      color="secondary"
                      size="6"
                    />
                  )}
                </div>
                {!this.state.isLoading && (
                  <Card className="card-login">
                    <CardHeader>
                      <h3 className="header text-center">FORGOT PASSWORD</h3>
                      <p
                        className={"font-color"}
                        style={{ marginTop: "8px", textAlign: "center" }}
                      >
                        Please enter your account email below and tap the
                        "Forgot password" button. A email will be sent with a
                        link to reset your password.
                      </p>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          value={this.state.email}
                          style={{
                            fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                          }}
                          placeholder="Email"
                          type="email"
                        />
                      </InputGroup>
                      <br />
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        className="btn-round mb-3"
                        onClick={this.buttonClick}
                        style={{ backgroundColor: "rgb(30,30,30)" }}
                      >
                        Forgot Password
                      </Button>
                      <Button
                        block
                        className="btn-round mb-3"
                        onClick={() => {
                          this.props.history.push(`login`);
                        }}
                        style={{ backgroundColor: "rgb(30,30,30)" }}
                      >
                        Back
                      </Button>
                    </CardFooter>
                  </Card>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ForgotPassword;
