/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useBusiness } from "context/BusinessContext.js";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Collapse, Nav } from "reactstrap";
import logo from "assets/business/logo-long.png";
import { checkAccess } from "../../utils/checkAccess";

function Sidebar(props) {
  let { business, businessDispatch } = useBusiness();
  // console.log("business", business)
  // console.log("props", props)
  // const [filteredRoutes, setFilteredRoutes] = useState(props.routes);
  // console.log("filteredRoutes", filteredRoutes);
  useEffect(() => {
    let ps;
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sideBarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);

  // useEffect(() => {
  //   setFilteredRoutes(props.routes);
  // }, [])

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {
      adminPicture: null,
      adminEmail: null,
    };
    routes.map((route, key) => {
      if (route.collapse) {
        initialState = {
          [route.state]: getCollapseInitialState(route.views),
          ...getCollapseStates(route.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  const [state, setState] = useState(getCollapseStates(props.routes));

  const sideBarRef = useRef();

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((route, key) => {
      if (route.redirect) {
        return null;
      }
      if (route.collapse) {
        var st = {};
        st[route["state"]] = !state[route.state];
        return (
          <li
            className={getCollapseInitialState(route.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[route.state]}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {route.icon !== undefined ? (
                <>
                  <i className={route.icon} />
                  <p>
                    {route.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{route.mini}</span>
                  <span className="sidebar-normal">
                    {route.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={state[route.state]}>
              <ul className="nav">{createLinks(route.views)}</ul>
            </Collapse>
          </li>
        );
      }

      //Check if the component is accessible to the admin

      return route.hidden == true ? null : (
        <li className={activeRoute(route.layout + route.path)} key={key}>
          <NavLink to={route.layout + route.path} activeClassName="">
            {route.icon !== undefined ? (
              <>
                <i className={route.icon} />
                <p>{route.name}</p>
              </>
            ) : route.materialIcon !== undefined ? (
              <>
                <span className="sidebar-mini-icon">{route.materialIcon}</span>
                <span className="sidebar-normal">{route.name}</span>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{route.mini}</span>
                <span className="sidebar-normal">{route.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo" style={{ textAlign: "center" }}>
        {/* <div className="logo" className="logo-mini">
          <img src={logoUrl || logo} alt="hd-group-logo" />
        </div> */}

        <div className="logo-mini">
          {/* <img
            src={logoUrl.default}
            style={{
              width: `${logoUrl.default.includes("pdc") ? "140px" : "160px"}`,
              height: `${logoUrl.default.includes("pdc") ? "140px" : "100px"}`,
              marginTop: 10,
            }}
            alt="business-logo"
          /> */}
          <img
            src={logo}
            style={{
              // width: "150px",
              // height: "120px",
              marginTop: 10,
            }}
            alt="business-logo"
          />
        </div>

        {/* <div className="info d-flex justify-content-center">
          <span style={{ color: "#2ba9cd", fontWeight: "bold" }}>
            {businessContext.business &&
              businessContext.business.business &&
              businessContext.business.business.name}
          </span>
        </div> */}
      </div>

      <div className="sidebar-wrapper" ref={sideBarRef}>
        <div className="user">
          {/* <div className="photo">
            <img uri={state.adminPicture} alt="" />
          </div> */}
          <div className="info">
            <a
            //  href="#pablo"
            //  data-toggle="collapse"
            ///aria-expanded={state.openAvatar}
            //  onClick={() =>
            //    setState({ openAvatar: !state.openAvatar })
            // }
            >
              <span style={{ color: "#2ba9cd" }}>
                {business?.user?.firstName}
              </span>
              <span style={{ color: "#2ba9cd" }}>{business?.user?.email}</span>
              <span style={{ marginTop: "5%", color: "#FFFFFF" }}>
                {/* Increment the version number with each update */}
                {/* dev version */}
                {/* Version: 2.1 */}
                {/* prod version */}
                Version: 1.1
              </span>
              <span style={{ marginTop: "5%", color: "#FFFFFF" }}>
                {business?.profile?.business?.name}
              </span>
              <span style={{ color: "#FFFFFF" }}>
                {business?.profile?.business?.address}
              </span>
            </a>
          </div>
        </div>
        <Nav>
          {createLinks(props.routes)}
          <li>
            <a
              data-toggle="collapse"
              onClick={(e) => {
                props.onLogout();
              }}
            >
              <i className="nc-icon nc-minimal-left" />
              <p>Logout</p>
            </a>
          </li>
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
