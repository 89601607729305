const businessReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload.user };
    case "SET_PROFILE":
      return { ...state, profile: action.payload.profile };
    case "REMOVE_USER":
      return { ...state, user: null, profile: null };
    case "SET_BUSINESS":
      return { ...state, business: action.payload.business };
    case "SET_LOADING":
      console.log("setloading", action.payload.loading);
      return { ...state, loading: action.payload.loading };
    default:
      return state;
  }
};

export default businessReducer;
