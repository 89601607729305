import axios from "axios";
// import "eventsource";

//for server sent events
import React, { useState, useEffect } from "react";

export const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://staffing-node.herokuapp.com"
    : "https://staffing-node.herokuapp.com";

const api = (
  config = {},
  token = JSON.parse(localStorage.getItem("Token"))
) => {
  //const config = {};
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + token,
      BusinessId: localStorage.getItem("BusinessId"),
    };
  }
  return axios.create({
    baseURL,
    timeout: 45000,
    ...config,
  });
};

export const eventSource = (url, updateData) => {
  let newBaseUrl = baseURL.endsWith("/") ? baseURL.slice(0, -1) : baseURL;
  let newUrl = newBaseUrl.concat(url);
  const source = new EventSource(newUrl);

  source.onmessage = function logEvents(event) {
    updateData(JSON.parse(event.data));
  };
  return source;
};

export default api;
