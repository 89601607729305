import React, { useContext, useReducer } from "react";
import businessReducer from "reducers/businessReducer";

export const BusinessContext = React.createContext();

const initialState = () => {
  return {
    user: null,
    business: null,
    profile: null,
    loading: false,
  };
  //return { user: null, agency: null, profile: null };
};

function BusinessProvider({ children }) {
  const [business, businessDispatch] = useReducer(
    businessReducer,
    initialState()
  );

  //   const loadAgencyFromSlug = (slug) => {
  //     api()
  //       .get(`/business/get/${slug}`)
  //       .then((result) => {
  //         businessDispatch({
  //           type: "SET_BUSINESS",
  //           payload: { business: result.data },
  //         });
  //       })
  //       .catch((e) => {
  //         props.history.push(`/${slug}/error`);
  //         console.log(e);
  //       });
  //   };
  return (
    <BusinessContext.Provider value={{ business, businessDispatch }}>
      {children}
    </BusinessContext.Provider>
  );
}

export default BusinessProvider;

export const useBusiness = () => {
  const { business, businessDispatch } = useContext(BusinessContext);
  return { business, businessDispatch };
};
