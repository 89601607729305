import logo from "assets/img/apple-icon.png";
import Footer from "components/Footer/Footer.jsx";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import PerfectScrollbar from "perfect-scrollbar";
import React, { useEffect, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import ForgotPassword from "views/pages/ForgotPassword.jsx";
import Login from "views/pages/Login.jsx";

var ps;

const Pages = () => {
  // const fullPages = useRef();
  // useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(fullPages.current);
  //   }

  //   return () => {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // }, []);

  return (
    <>
      {/* <AuthNavbar logo={logo} companyName={"HD group Plants"} />
      <div className="wrapper wrapper-full-page" ref={fullPages}>
        <div className="full-page section-image"> */}
      <Switch>
        <Route
          exact
          path="/auth/forgot-password"
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          exact
          path="/auth/login"
          render={(props) => <Login {...props} />}
        />
        {/* {this.getRoutes(routes)} */}
      </Switch>
      {/* <Footer fluid />
        </div>
      </div> */}
    </>
  );
};

export default Pages;
