import api from "components/API/api.js";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import { useBusiness } from "context/BusinessContext.js";
import { useModal } from "context/ModalContext.js";
import PerfectScrollbar from "perfect-scrollbar";
import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import routes from "./routes.js";

let ps;

const Admin = (props) => {
  const mainPanel = useRef();
  const { business, businessDispatch } = useBusiness();
  const history = useHistory();
  const [activeColor, setActiveColor] = useState("info");
  const [backgroundColor, setBackGroundColor] = useState("black");
  const [sideBarMini, setSideBarMini] = useState();
  const { addAlert } = useModal();

  // console.log("Business loading", business.loading);

  const processLoginData = (user) => {
    // console.log("processing", user);
    let profile = user.profile.find((p) => !!p.business);
    // console.log("profile", profile);
    if (profile?.business?._id) {
      let businessId = profile.business._id;
      localStorage.setItem("BusinessId", businessId);
      businessDispatch({
        type: "SET_USER",
        payload: { user: user },
      });
      businessDispatch({
        type: "SET_PROFILE",
        payload: { profile: user.profile },
      });
      businessDispatch({
        type: "SET_LOADING",
        payload: { loading: false },
      });
      // profile.role === "BusinessHr"
      // ? history.push(`/admin/live-business`)
      // : history.push(`/admin/live-selected-users`)
      // console.log("business setsdfsadff");
    } else {
      addAlert({
        error: true,
        title: "Invalid email or password, please try again.",
      });
    }
  };

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel.current);
    }

    if (localStorage.Token) {
      console.log("1");
      if ((!business?.user || !business?.profile) && !business.loading) {
        let token = JSON.parse(localStorage.getItem("Token"));
        if (token) {
          businessDispatch({
            type: "SET_LOADING",
            payload: { loading: true },
          });
          // console.log("started");
          api()
            .get("/users/login/validate-token")
            .then(({ data }) => {
              // console.log("logged in!", data);
              processLoginData(data);
            })
            .catch((e) => {
              console.log("error");
              localStorage.removeItem("BusinessId");
              localStorage.removeItem("Token");
              history.push(`/auth/login`);
            });
        }
      }
    } else {
      localStorage.removeItem("BusinessId");
      localStorage.removeItem("Token");
      history.push(`/auth/login`);
    }

    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, []);

  const getRoutes = (routes) => {
    //If the user is not logged in i.e. localstorage has no "Token" then the page will redirect to Login page
    if (!JSON.parse(localStorage.getItem("Token"))) {
      return <Redirect to={`/auth/login`} />;
    }

    return routes.map((route, key) => {
      if (route.collapse) {
        return getRoutes(route.views);
      }

      return (
        <Route
          path={route.layout + route.path}
          component={route.component}
          key={key}
        />
      );
    });
  };

  const computeFilteredRoutes = (routes) => {
    if (!routes) {
      return [];
    }
    try {
      // console.log("routes", routes)
      // console.log("business", business)
      const userRole = business.profile.role || business.profile[0]?.role;
      const filteredRoutes = routes
        .map((route) => {
          if (userRole && route.access.includes(userRole)) {
            return { ...route, views: computeFilteredRoutes(route.views) };
          }
          return null;
        })
        .filter((route) => route);
      // console.log("filteredRoutes", filteredRoutes);
      // console.log("userRole", userRole);
      // setFilteredRoutes(filteredRoutes);
      return filteredRoutes;
    } catch (error) {
      return routes;
    }
  };

  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSideBarMini(false);
    } else {
      setSideBarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };
  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={computeFilteredRoutes(business?.profile ? routes : [])}
        bgColor={backgroundColor}
        activeColor={activeColor}
        onLogout={() => {
          api()
            .post("/users/logout")
            .then(() => {
              console.log("logged out from server");
              return true;
            })
            .catch((e) => {
              console.log(e);
            });
          localStorage.setItem("Token", null);
          businessDispatch({ type: "REMOVE_USER" });
          history.push(`/auth/login`);
        }}
      />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
        <Switch>{getRoutes(routes)}</Switch>
        {/* {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid />
          )
        } */}
      </div>
      {/* <FixedPlugin
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          sidebarMini={this.state.sidebarMini}
          handleActiveClick={this.handleActiveClick}
          handleBgClick={this.handleBgClick}
          handleMiniClick={this.handleMiniClick}
        /> */}
    </div>
  );
};

export default Admin;
