import LandingPageHeader from "./LandingPageHeader.js";
import React from "react";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  return (
    <>
      <LandingPageHeader />
    </>
  );
}

export default LandingPage;
