import { Modal } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import Axios from "axios";
import api from "components/API/api";
import ImageUpload from "components/CustomUpload/ImageUploadControlled.jsx";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import { useModal } from "context/ModalContext";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormGroup } from "reactstrap";
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Input from "reactstrap/lib/Input";
import Row from "reactstrap/lib/Row";
import EditImageModal from "./Images/EditImageModal";

const DocumentStatus = {
  pending: 0,
  valid: 1,
  invalid: 2,
};

// interface IDocument {
//   type: DocumentType;
//   status: DocumentStatus;
//   fileName: string;
//   documentNumber?: string;
//   expires?: Date;
// }

const DocumentType = {
  id: 0,
  workPermit: 1,
  sin: 2,
  studyPermit: 3,
  sinApplicationProof: 4,
  signature: 5,
};

const DocumentTypeString = [
  "* Government Issued Photo ID (passport or driver's license)",
  "* Work Permit",
  "* Proof of Sin",
  "* Study Permit",
  "* Sin Application Proof",
  "* Signature",
];

// const DocumentStatus = {
//   pending: 0,
//   valid: 1,
//   invalid: 2,
// };
const getDocumentType = (index) => {
  switch (index) {
    case 1:
      return DocumentType.id;
    case 3:
      return DocumentType.sin;
    case 5:
      return DocumentType.workPermit;
    case 7:
      return DocumentType.studyPermit;
    case 8:
      return DocumentType.signature;
  }
};

const Images = ({ documents }) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [myDocuments, setMyDocuments] = useState([]);
  const { addAlert } = useModal();
  const [removeImageModal, setRemoveImageModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [addImageModal, setAddImageModal] = useState(false);

  // HIDING FOR PRIVACY
  // useEffect(() => {
  //   loadImages();
  // }, []);

  const uploadFile = (file, signedRequest) => {
    Axios.put(signedRequest, file)
      .then(() => {
        console.log("successfully uploaded the image");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleImages = async (image, fileName) => {
    await api()
      .get("/image/sign-s3/put", {
        params: {
          "file-path": `userDocs/${id}/${fileName}`,
          "file-type": image.type,
        },
      })
      .then(({ data }) => {
        uploadFile(image, data.signedUrl);
        return true;
      })
      .catch((e) => console.log(e));
  };

  const loadImages = async () => {
    try {
      setLoading(true);
      const { data } = await api().get(`/users/documents?userId=${id}`);
      setLoading(false);
      setMyDocuments(
        data.map((_doc) => ({ ..._doc, imagePreviewUrl: _doc.url }))
      );
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  // const update = async (imageId, userId, updates) => {
  //   try {
  //     await api().patch("/users/documents", {
  //       updates,
  //       userId: userId,
  //       documentId: imageId,
  //     });

  //     addAlert({
  //       title: "User successfully updated",
  //       success: true,
  //     });
  //   } catch (e) {
  //     addAlert({
  //       title: e.response?.data?.message || e.message,
  //       error: true,
  //     });
  //   } finally {
  //     setShowImageModal(false);
  //     setPreviewImage(null);
  //   }
  // };

  // const insert = async (userId, document) => {
  //   try {
  //     const { data } = await api().post("/users/documents", {
  //       document,
  //       userId: userId,
  //     });
  //     // console.log(document);

  //     addAlert({
  //       title: "Document successfully updated",
  //       success: true,
  //     });
  //     return data._id;
  //   } catch (e) {
  //     addAlert({
  //       title: e.response?.data?.message || e.message,
  //       error: true,
  //     });
  //   } finally {
  //     setShowImageModal(false);
  //     setPreviewImage(null);
  //   }
  // };
  // const approve = async (image) => {
  //   let documentId = image._id;
  //   if (documentId) {
  //     await update(documentId, id, { status: DocumentStatus.valid });
  //   } else {
  //     documentId = await insert(id, { ...image, status: DocumentStatus.valid });
  //     console.log(documentId);
  //   }
  //   setMyDocuments((documents) => {
  //     return documents.map((_document) => {
  //       if (_document.fileName === image.fileName) {
  //         _document.status = DocumentStatus.valid;
  //         _document._id = documentId;
  //       }
  //       return _document;
  //     });
  //   });
  // };
  // const reject = async (image) => {
  //   let documentId = image._id;
  //   if (documentId) {
  //     await update(image._id, id, { status: DocumentStatus.invalid });
  //   } else {
  //     documentId = await insert(id, { ...image, status: DocumentStatus.invalid });
  //     console.log(documentId);
  //   }
  //   setMyDocuments((documents) => {
  //     return documents.map((_document) => {
  //       if (_document.fileName === image.fileName) {
  //         _document.status = DocumentStatus.invalid;
  //       }
  //       return _document;
  //     });
  //   });
  // };

  // const updateExpiry = async (image, date) => {
  //   let documentId = image._id;
  //   if (documentId) {
  //     await update(documentId, id, { expires: date });
  //   } else {
  //     documentId = await insert(id, { ...image, expires: date });
  //   }
  //   const updates = {
  //     userId: id,
  //     sinExpiry: moment(date).format(),
  //   };
  //   await api().patch(`users`, updates);

  //   setMyDocuments((documents) => {
  //     return documents.map((_document) => {
  //       if (_document.fileName === image.fileName) {
  //         _document.expires = date;
  //         _document._id = documentId;
  //       }
  //       return _document;
  //     });
  //   });
  // };

  const closeRemoveImageModal = () => {
    setRemoveImageModal(false);
  };

  const RemoveImageModal = () => {
    const [_loading, _setLoading] = useState(false);
    return (
      <Modal
        open={removeImageModal}
        onClose={closeRemoveImageModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup
          style={{ width: 400, backgroundColor: "#fff", borderRadius: 10 }}
        >
          {_loading && <LoadingOverlay />}
          <div style={{ padding: 25, marginTop: 20 }}>
            <p>Are you sure you want to delete the image?</p>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                onClick={async () => {
                  _setLoading(true);
                  await handleRemoveImages(selectedDocument);

                  _setLoading(false);
                }}
                color="danger"
              >
                Delete
              </Button>
              <Button onClick={closeRemoveImageModal}>Close</Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const closeAddImageModal = () => {
    setAddImageModal(false);
  };

  const handleRemoveImages = async (image) => {
    setLoading(true);
    try {
      console.log(image);
      const { data } = await api().patch("/users/documents/delete", {
        userId: id,
        document: image,
      });

      setMyDocuments((documents) => {
        return documents.filter((_document) => _document._id !== image._id);
      });
      addAlert({
        title: "Image Successfully removed",
        success: true,
      });
    } catch (e) {
      addAlert({
        title: e.response?.data?.message || e.message,
        error: true,
      });
    } finally {
      closeRemoveImageModal();
      setLoading(false);
    }
  };

  const Image = ({ image }) => {
    return (
      <div
        className="d-flex flex-column align-items-center mb-2"
        style={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          minHeight: "600px",
        }}
      >
        {/* <div
          style={{
            position: "absolute",
            top: "10px",
            right: "30px",
            color: "red",
            cursor: "pointer",
          }}
          onClick={() => {
            setRemoveImageModal(true);
            setSelectedDocument(image);
          }}
        >
          <Delete fontSize="default" />
        </div> */}
        <h3>{DocumentTypeString[image.type]}</h3>
        {(image.type === DocumentType.workPermit ||
          image.type === DocumentType.studyPermit ||
          image.type === DocumentType.sin) && (
          <Row
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col>Expiry:</Col>
            <Col>
              {/* <ReactDateTime
                defaultValue={image.expires ? moment(image.expires).format("MM/DD/YYYY") : ""}
                timeFormat={false}
                closeOnSelect={true}
                value={image.expires ? moment(image.expires).format("MM/DD/YYYY") : ""}
              /> */}
              <Input
                type="text"
                disabled={true}
                value={
                  image.expires
                    ? moment(image.expires).format("DD MMM, YYYY")
                    : ""
                }
              />
            </Col>
          </Row>
        )}
        {(image.type === DocumentType.sin ||
          image.type === DocumentType.sinApplicationProof) && (
          <Row
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col>Document Number:</Col>
            <Col>
              <Input disabled value={image.documentNumber} />
            </Col>
          </Row>
        )}
        <Row className="mb-2">
          <Col>
            <div
              style={{
                backgroundColor:
                  image.status === DocumentStatus.valid
                    ? "#20c997"
                    : image.status === DocumentStatus.invalid
                    ? "#dc3545"
                    : "gray",
                color: "white",
                padding: "5px 10px",
                borderRadius: "5px",
              }}
            >
              {image.status === DocumentStatus.invalid ? (
                "Invalid"
              ) : image.status === DocumentStatus.valid ? (
                <div>Valid</div>
              ) : (
                "Pending Approval"
              )}
            </div>
          </Col>
          {(!image.expires || moment(image.expires).isBefore(moment())) && (
            <Col>
              <div
                style={{
                  backgroundColor: "#dc3545",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                Expired
              </div>
            </Col>
          )}
        </Row>
        <ImageUpload
          localImage={image}
          index={image._id}
          handleImages={(_image) => {
            handleImages(_image, image.fileName);
          }}
          onSelect={() => {
            if (loading) return;
            // setSelectedDocument(image);
            // setAddImageModal(image);
          }}
        />
        {/* </Col> */}
      </div>
    );
  };

  return (
    <>
      <Row style={{ minHeight: "800px" }}>
        {loading && <LoadingOverlay />}
        {myDocuments.map((image, i) => {
          return (
            <Col md={6} key={i} className="h-10">
              <Image image={image} />
            </Col>
          );
        })}
        {/* <Col md={6} className="h-10">
          <div
            className="d-flex flex-column align-items-center mb-2 justify-content-center"
            style={{ border: "1px solid #ccc", borderRadius: "5px", minHeight: "600px" }}
          >
            <Button
              onClick={() => {
                setSelectedDocument(null);
                setAddImageModal(true);
              }}
            >
              <Add />
              Add
            </Button>
          </div>
        </Col>
       */}
      </Row>
      <RemoveImageModal />
      {addImageModal && (
        <EditImageModal
          addImageModal={addImageModal}
          closeAddImageModal={closeAddImageModal}
          document={selectedDocument}
          onUpdate={(updates) => {
            setMyDocuments((documents) => {
              return documents.map((_document) => {
                if (_document._id === selectedDocument._id) {
                  return {
                    ..._document,
                    ...updates,
                  };
                }
                return _document;
              });
            });
          }}
          onAdd={(newImage) => {
            setMyDocuments((docs) => [...docs, newImage]);
          }}
        />
      )}
    </>
  );
};

export default Images;
