import React, { useState, useEffect } from "react";
// reactstrap components
import {
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  Row,
  Col,
  Spinner,
  Button,
  FormGroup,
} from "reactstrap";
import ReactTable from "react-table";
import api from "components/API/api";
import moment from "moment";
import Select from "react-select";
import { Modal } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";

function UserTrainingHistory({ userId }) {
  const [trainingHistory, setTrainingHistory] = useState([]);
  const [qualificationInviteHistory, setQualificationInviteHistory] = useState(
    []
  );
  const [qualificationList, setQualificationList] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({});
  const [alert, setAlert] = useState(null);
  //react table states
  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 5,
    field: "completedDate",
    sort: "desc",
    filter: [],
  });
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(() => {
    getWorkerTrainingHistory();
    getWorkerQualificationInviteHistory();
    getQualificationList();
    setLoadingTable(true);
  }, []);

  //function to get history
  const getWorkerTrainingHistory = async (filterOptions = reactTableState) => {
    try {
      setIsLoading(true);
      let params = {
        ...filterOptions,
        userId,
      };
      console.log(params);
      const { data } = await api().get(`/training-history`, { params });
      let tempHistory = [];
      data.trainingHistories.map((training) => {
        let history = {
          completedDate: moment(training.completedDate).format(
            "MMM Do YYYY, h:mm a"
          ),
          expires: moment(training.expires).format("MMM Do YYYY, h:mm a"),
          name: training.name,
        };
        tempHistory.push(history);
      });
      setTrainingHistory(tempHistory);
      setIsLoading(false);
      setLoadingTable(false);
    } catch (e) {
      console.log("error loading training history");
      console.log(e);
      setIsLoading(false);
      setLoadingTable(false);
    }
  };

  const getWorkerQualificationInviteHistory = async () => {
    try {
      const { data } = await api().get(`/qualification-invite/history`, {
        params: { userId },
      });
      setQualificationInviteHistory(data);
    } catch (error) {
      console.log("could not fetch qualification invites", error);
    }
  };

  const getQualificationList = async () => {
    try {
      const { data } = await api().get("/qualification", {
        params: { size: 1000 },
      });
      setQualificationList(data.qualifications);
    } catch (error) {
      console.log("could not fetch qualification invites", error);
    }
  };

  const loadingOverlay = () => {
    return (
      <div style={{ display: "flex" }}>
        <Spinner
          color="primary"
          style={{ margin: "300px auto", width: "3rem", height: "3rem" }}
        />
      </div>
    );
  };

  const handleOpenQualificationInviteModal = () => {
    setOpenModal({ qualificationInvite: true });
  };

  return (
    <div className="content">
      {isLoading ? (
        loadingOverlay()
      ) : (
        <>
          <CardBody>
            <Row>
              <Col>
                {trainingHistory.length > 0 ? (
                  <ReactTable
                    filterable
                    columns={[
                      {
                        Header: "Training name",
                        accessor: "name",
                      },
                      {
                        Header: "Completed Date",
                        accessor: "completedDate",
                        maxWidth: 200,
                      },
                      {
                        Header: "expires",
                        accessor: "expires",
                        maxWidth: 200,
                      },
                    ]}
                    manual
                    data={trainingHistory}
                    loading={loadingTable}
                    defaultPageSize={10}
                    pageSizeOptions={[10, 15, 30, 60]}
                    showPaginationTop={true}
                    showPaginationBottom={false}
                    className="-striped -highlight primary-pagination"
                  />
                ) : (
                  <Label>No training history found for this user</Label>
                )}
              </Col>
            </Row>
          </CardBody>
        </>
      )}
      {alert}
    </div>
  );
}

export default UserTrainingHistory;
