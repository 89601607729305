import { Sync, Visibility } from "@material-ui/icons";
import api from "components/API/api";
import CustomCalendar from "components/Calendar/CustomCalendar";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import { useModal } from "context/ModalContext";
import { useSocket } from "context/SocketContext";
import { useBusiness } from "context/BusinessContext";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactTable from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import {
  afternoonTimeRange,
  morningTimeRange,
  nightTimeRange,
} from "utils/dateUtils";
import { getDay, startOfDay, startOfNextDay } from "utils/dateUtils.js";
import { CSVLink } from "react-csv";
import CustomExportExcel, {
  customExcelData,
} from "components/Excel/CustomExportExcel";

const RequiredWorkersCell = ({
  _id,
  businessName,
  value,
  onChange,
  shiftStart,
  shiftEnd,
}) => {
  const [clicked, setClicked] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const [loading, setLoading] = useState(false);
  const { addAlert } = useModal();
  const _onEnter = () => {
    setClicked(false);
    if (newValue !== value) {
      let openDate = moment(shiftStart).set({ hour: 23, minute: 59 }).format();
      let closeDate = moment(shiftStart).set({ hour: 0, minute: 0 }).format();
      const data = {
        noRequiredWorkers: parseInt(newValue),
        requiredPeople: parseInt(newValue),
        oldRequiredPeople: parseInt(value),
        businessName,
        sendNotification: true,
        // openDate,
        // closeDate,
        selectedShiftStart: shiftStart,
        selectedShiftEnd: shiftEnd,
        // selectedDateStart: startOfDay(shiftStart),
        // selectedDateEnd: startOfNextDay(shiftStart),
      };

      if (!data.noRequiredWorkers && data.noRequiredWorkers !== 0) {
        setNewValue(value);
      } else {
        setLoading(true);
        api({
          headers: {
            client_timezone: moment?.tz.guess(),
            client_timestamp: moment().format(),
          },
        })
          .patch(`/shift/${_id}`, data)
          .then(() => {
            setLoading(false);
            onChange(newValue, _id);
          })
          .catch((e) => {
            setLoading(false);
            setNewValue(value);
            addAlert({
              error: true,
              message: e?.response?.data?.message || e.message,
            });
          });
      }
    } else {
      setNewValue(value);
    }
  };
  return (
    <div className="w-100 h-100" style={{ cursor: "pointer" }}>
      {loading ? (
        <Spinner />
      ) : clicked ? (
        <Input
          type="text"
          value={newValue}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              _onEnter();
            }
          }}
          onBlur={() => {
            _onEnter();
          }}
          autoFocus={clicked}
          onChange={(e) => {
            setNewValue(e.target.value);
          }}
        />
      ) : (
        <p onClick={() => setClicked(true)} className="w-100 h-100">
          {value}
        </p>
      )}
    </div>
  );
};

const Module = function ({ business, currentDate, onUpdate }) {
  const history = useHistory();
  const { addAlert } = useModal();
  const [noMorningWorkers, setNoMorningWorkers] = useState(0),
    [noAfternoonWorkers, setNoAfterNoonWorkers] = useState(0),
    [noNightWorkers, setNoNightWorkers] = useState(0);

  const [noMorningBackupWorkers, setNoMorningBackupWorkers] = useState(0),
    [noAfternoonBackupWorkers, setNoAfterNoonBackupWorkers] = useState(0),
    [noNightBackupWorkers, setNoNightBackupWorkers] = useState(0);

  const [noMorningRequired, setNoMorningRequired] = useState(0),
    [noAfternoonRequired, setNoAfterNoonRequired] = useState(0),
    [noNightRequired, setNoNightRequired] = useState(0);

  const [shiftsData, setShiftsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [exportHeaders, setExportHeaders] = useState([
    {
      label: "HD ID",
      key: "hdId",
    },
    {
      label: "First Name",
      key: "firstName",
    },
    {
      label: "Last Name",
      key: "displayLastName",
    },
    {
      label: "Function",
      key: "shiftFunctionName",
    },
    {
      label: "Pay Rate",
      key: "shiftFunctionPayRate",
    },
    {
      label: "Clocked In",
      key: "clockInTime",
    },
    {
      label: "Shift Start",
      key: "shiftStart",
    },
    {
      label: "Shift End",
      key: "shiftEnd",
    },
    {
      label: "Clocked Out",
      key: "clockOutTime",
    },
    {
      label: "Total Hours",
      key: "totalHours",
    },
    {
      label: "Status",
      key: "statusName",
    },
    {
      label: "Last Approval Update By",
      key: "lastApprovalUpdate",
    },
    {
      label: "Reg Approval",
      key: "supervisorApproval",
    },
    {
      label: "Excess Hours Approval", // Renamed on Dufflet request 2023-06-07 - Helen
      key: "supervisorApprovalOT",
    },
  ]);
  const [exportLoading, setExportLoading] = useState(false);
  const [displayExport, setDisplayExport] = useState(false);

  const [download, setDownload] = useState(false);
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    let morning = morningTimeRange(currentDate),
      afternoon = afternoonTimeRange(currentDate),
      night = nightTimeRange(currentDate);

    let noMorningWorkers = 0,
      noAfternoonWorkers = 0,
      noNightWorkers = 0;

    let noMorningBackupWorkers = 0,
      noAfternoonBackupWorkers = 0,
      noNightBackupWorkers = 0;

    let noMorningRequired = 0,
      noAfternoonRequired = 0,
      noNightRequired = 0;

    let noMorningNonConfirmed = 0,
      noAfternoonNonConfirmed = 0,
      noNightNonConfirmed = 0;

    //console.log("business.shifts", business.shifts);

    business &&
      business.shifts.forEach((shift) => {
        let start = new Date(shift.start);

        if (moment(start).isBetween(morning.start, morning.end)) {
          noMorningWorkers += shift.noSelected;
          noMorningBackupWorkers += shift.noBackup;
          noMorningRequired += shift.noRequiredWorkers;
          noMorningNonConfirmed += shift.noApplied;
        } else if (moment(start).isBetween(afternoon.start, afternoon.end)) {
          noAfternoonWorkers += shift.noSelected;
          noAfternoonBackupWorkers += shift.noBackup;
          noAfternoonRequired += shift.noRequiredWorkers;
          noAfternoonNonConfirmed += shift.noApplied;
        } else if (moment(start).isBetween(night.start, night.end)) {
          noNightWorkers += shift.noSelected;
          noNightBackupWorkers += shift.noBackup;
          noNightRequired += shift.noRequiredWorkers;
          noNightNonConfirmed += shift.noApplied;
        }
      });

    setNoMorningWorkers(noMorningWorkers);
    setNoAfterNoonWorkers(noAfternoonWorkers);
    setNoNightWorkers(noNightWorkers);

    setNoMorningBackupWorkers(noMorningBackupWorkers);
    setNoAfterNoonBackupWorkers(noAfternoonBackupWorkers);
    setNoNightBackupWorkers(noNightBackupWorkers);

    setNoMorningRequired(noMorningRequired);
    setNoAfterNoonRequired(noAfternoonRequired);
    setNoNightRequired(noNightRequired);

    setData(business);
  }, [business, currentDate]);

  useEffect(() => {
    setDisplayExport(false);
    setExportLoading(false);
  }, [currentDate]);

  const setData = async (business) => {
    try {
      let shifts = business.shifts;
      let newShifts = shifts
        .map((s) => ({
          ...s,
          // start: stringifyTimeStamp(new Date(s.start)) + " " + dateToTimeString(new Date(s.start)),
          // end: stringifyTimeStamp(new Date(s.end)) + " " + dateToTimeString(new Date(s.end)),
          startDate: moment(s.start).format("hh:mm A"),
          endDate: moment(s.end).format("hh:mm A"),
          actions: RenderActionButtons(s, business),
        }))
        .sort((a, b) => moment(a.start) - moment(b.start));
      setShiftsData(newShifts);
    } catch (error) {
      addAlert({
        error: true,
        title: error.message,
      });
      console.log(error);
    }
  };

  const RenderActionButtons = (s, business) => {
    return (
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => {
            let search = new URLSearchParams();
            search.append("date", moment(currentDate).format());
            search.append("shift", s._id);
            history.push(`/admin/all-live-shift-workers?${search.toString()}`);

            //history.push("/admin/all-business/agency/selected-users");
          }}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  // fetch users for export
  const fetchExportUsers = async (businessShiftData, _date) => {
    setExportLoading(true);
    let tempCSVExportData = [];
    businessShiftData.forEach((shift) => {
      api()
        .get(`selected-for-work/users/${shift._id}`, {
          params: {
            start: startOfDay(new Date(_date)),
            end: startOfNextDay(new Date(_date)),
          },
        })
        .then(({ data }) => {
          let newData = data.map((s) => {
            const clockIn = s.check?.in;
            const clockOut = s.check?.out;
            const supervisorApproval = s.supervisorApproval ? true : false;
            const supervisorApprovalOT = s.supervisorApprovalOT ? true : false;

            const defaultClockIn =
              s.check?.in?.time > s.start ? s.check.in.time : s.start;
            const defaultClockOut =
              s.check?.out?.time < s.end ? s.check.out.time : s.end;

            // always use the LATER of scheduled start and clock in. They never start early
            const defaultClockInToSubtract = s.check?.in?.time
              ? moment(defaultClockIn).add(30, "minutes")
              : moment(s.start).add(30, "minutes");

            let totalHours = 0;

            if (supervisorApprovalOT === true) {
              // overtime approval (more than scheduled end), use clock out
              totalHours =
                clockIn && clockOut
                  ? moment(clockOut.time)
                      .diff(defaultClockInToSubtract, "hours", true)
                      .toFixed(2)
                  : 0;
            } else if (supervisorApproval === true) {
              // regular approval, use default times or later clock in/earlier clock out if exists

              let regStart = s.check?.in?.time
                ? defaultClockInToSubtract
                : moment(s.start).add(30, "minutes");
              let regEnd = s.check?.out?.time ? defaultClockOut : s.end;

              totalHours = moment(regEnd)
                .diff(regStart, "hours", true)
                .toFixed(2);
            }

            return {
              ...s,
              shiftStart: s.start ? moment(s.start).format("h:mm a") : "-",
              shiftEnd: s.end ? moment(s.end).format("h:mm a") : "-",
              displayLastName:
                s.displayLastName || s.duplicated
                  ? s.lastName + "-" + s.duplicated
                  : s.lastName,
              comment: s.comment ? s.comment.note : null,
              lastAppLoginTime: s.lastAppLogin
                ? moment(s.lastAppLogin).format("MMM Do YYYY, h:mm a")
                : null,
              vaccineStatus:
                s.vaccineStatus === 2
                  ? "Fully Vaccinated"
                  : s.vaccineStatus === 1
                  ? "1st dose"
                  : "No Vaccine",
              // actions: RenderActionButtons(s),
              shiftFunctionName: s.shiftFunction?.name
                ? s.shiftFunction.name
                : "-",
              shiftFunctionPayRate: s.shiftFunction?.payRate
                ? "$ " + s.shiftFunction.payRate
                : "-",
              clockIn,
              clockOut,
              totalHours,

              startTime: moment(s.start).format("h:mm a"),
              endTime: moment(s.end).format("h:mm a"),
              supervisorApproval: supervisorApproval,
              supervisorApprovalOT: supervisorApprovalOT,
              lastApprovalUpdate: s.supervisorName ? s.supervisorName : "",
            };
          });

          newData.map((worker) => {
            worker.clockInTime = worker.clockIn?.time
              ? moment(worker.clockIn?.time).format("hh:mm A")
              : "-";
            worker.clockOutTime = worker.clockOut?.time
              ? moment(worker.clockOut?.time).format("hh:mm A")
              : "-";

            if (worker.status === 1) {
              worker.statusName = "Confirmed";
              tempCSVExportData.push(worker);
            } else if (worker.status === 2) {
              worker.statusName = "Backup";
              tempCSVExportData.push(worker);
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });

    setTimeout(() => {
      // sort data based on shift start datetime. Earliest goes first
      tempCSVExportData.sort((a, b) => a.start.localeCompare(b.start));

      // calculate total hours for entire day
      const totalHoursForToday = tempCSVExportData.reduce(
        (n, { totalHours }) => n + Number(totalHours),
        0
      );
      tempCSVExportData.push({
        clockOutTime: "Total",
        totalHours: totalHoursForToday,
      });

      setExportData(tempCSVExportData);
      setExportLoading(false);
      console.log("tempCSVExportData", tempCSVExportData);
    }, 5000);
  };

  const exportDisplay = () => {
    return (
      <>
        {displayExport ? (
          <FormGroup>
            {exportLoading ? (
              "Please wait, Export is Loading"
            ) : (
              <>
                <CustomExportExcel
                  excelData={excelData}
                  download={download}
                  setDownload={(download) => setDownload(download)}
                  fileName={`${business.name}-${moment(currentDate).format(
                    "MMM-Do-YYYY"
                  )}-All-Shifts-Export.xlsx`}
                />
                <Button
                  color="default"
                  outline
                  onClick={() => {
                    let tempExcel = [];
                    console.log("export data", exportData);

                    tempExcel.push(
                      customExcelData({
                        shifts: exportData,
                        currentDate,
                        headers: exportHeaders,
                        businessName: business.name,
                        date: moment(currentDate).format("MMMM DD YYYY"),
                        day: moment(currentDate).format("dddd"),
                        weekly: false,
                        weekending: moment(currentDate)
                          .day("Saturday")
                          .format("MMMM DD YYYY"),
                      })
                    );
                    setExcelData(tempExcel);
                    setDownload(true);
                  }}
                >
                  Export Excel
                </Button>
                <CSVLink
                  data={exportData}
                  headers={exportHeaders}
                  filename={`${business.name}-${moment(currentDate).format(
                    "MMM-Do-YYYY"
                  )}-All-Shift-Export.csv`}
                >
                  {" "}
                  <Button color="default" outline>
                    Export CSV
                  </Button>
                </CSVLink>
                <Button
                  color="default"
                  outline
                  onClick={() => {
                    setDisplayExport(false);
                  }}
                >
                  Cancel Export
                </Button>
              </>
            )}
          </FormGroup>
        ) : (
          <Button
            color="default"
            outline
            onClick={() => {
              // prepare CSV export data
              setDisplayExport(true);
              fetchExportUsers(business.shifts, currentDate);
            }}
          >
            Prepare Export for Today
          </Button>
        )}
      </>
    );
  };

  const businessNameDisplay = () => {
    return (
      <div>
        <CardTitle tag="h6">{business.name}</CardTitle>
        <CardSubtitle
          tag="h7"
          className="mb-2 text-muted"
          style={{ display: "inline-block" }}
        >
          {!business.address && !business.intersection && "-"}
          {business.address} {business.intersection}
        </CardSubtitle>
      </div>
    );
  };

  const refreshButtonDisplay = () => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsLoading(true);
          api()
            .post("/shift/reset/counter", {
              from: startOfDay(currentDate),
              till: startOfNextDay(currentDate),
              businessId: business._id,
            })
            .then(() => {
              addAlert({
                success: true,
                title: "Successfully refreshed the counter!",
              });
              onUpdate();
            })
            .catch((e) => {
              addAlert({
                success: false,
                title: "Sorry! Couldn't refresh the counter!",
                children: e.response?.data?.message || e.message,
              });
            })
            .finally(() => setIsLoading(false));
        }}
      >
        <Sync fontSize="small" />
      </div>
    );
  };

  return (
    <Card className="no-transition" style={{ backgroundColor: "#fcfcfc" }}>
      <CardHeader>
        {window.innerWidth > 500 ? (
          <div className="d-flex justify-content-between">
            {businessNameDisplay()}
            {exportDisplay()}
            {refreshButtonDisplay()}
          </div>
        ) : (
          <>
            <div>{exportDisplay()}</div>
            <div className="d-flex justify-content-between">
              {businessNameDisplay()}
              {refreshButtonDisplay()}
            </div>
          </>
        )}
      </CardHeader>
      <CardBody>
        <div className="d-flex justify-content-between">
          <p style={{ flex: 2 }}></p>
          <p style={{ flex: 2, textAlign: "center" }}>Required</p>
          <p style={{ flex: 2, textAlign: "center" }}>Confirmed</p>
          <p style={{ flex: 2, textAlign: "center" }}>Backup</p>
          {/* <p style={{ flex: 2, textAlign: "center" }}>Available</p> */}
        </div>
        <div className="d-flex justify-content-between">
          <p style={{ flex: 2 }}>Morning</p>
          <p style={{ flex: 2, textAlign: "center" }}>{noMorningRequired}</p>
          <p style={{ flex: 2, textAlign: "center" }}>{noMorningWorkers}</p>
          <p style={{ flex: 2, textAlign: "center" }}>
            {noMorningBackupWorkers}
          </p>
          {/* <p style={{ flex: 2, textAlign: "center" }}>
            {noMorningNonConfirmed}
          </p> */}
        </div>
        <div className="d-flex justify-content-between">
          <p style={{ flex: 2 }}>Afternoon</p>
          <p style={{ flex: 2, textAlign: "center" }}>{noAfternoonRequired}</p>
          <p style={{ flex: 2, textAlign: "center" }}>{noAfternoonWorkers}</p>
          <p style={{ flex: 2, textAlign: "center" }}>
            {noAfternoonBackupWorkers}
          </p>
          {/* <p style={{ flex: 2, textAlign: "center" }}>
            {noAfternoonNonConfirmed}
          </p> */}
        </div>
        <div className="d-flex justify-content-between">
          <p style={{ flex: 2 }}>Night</p>
          <p style={{ flex: 2, textAlign: "center" }}>{noNightRequired}</p>
          <p style={{ flex: 2, textAlign: "center" }}>{noNightWorkers}</p>
          <p style={{ flex: 2, textAlign: "center" }}>{noNightBackupWorkers}</p>
          {/* <p style={{ flex: 2, textAlign: "center" }}>{noNightNonConfirmed}</p> */}
        </div>
        <hr style={{ border: "0.5px solid grey" }} />
        <div
          className="d-flex justify-content-between"
          style={{ fontWeight: "bold" }}
        >
          <p style={{ flex: 2 }}>Total</p>
          <p style={{ flex: 2, textAlign: "center" }}>
            {noMorningRequired + noAfternoonRequired + noNightRequired}
          </p>
          <p style={{ flex: 2, textAlign: "center" }}>
            {noMorningWorkers + noAfternoonWorkers + noNightWorkers}
          </p>
          <p style={{ flex: 2, textAlign: "center" }}>
            {noMorningBackupWorkers +
              noAfternoonBackupWorkers +
              noNightBackupWorkers}
          </p>
          {/* <p style={{ flex: 2, textAlign: "center" }}>
            {noMorningNonConfirmed +
              noAfternoonNonConfirmed +
              noNightNonConfirmed}
          </p> */}
        </div>
      </CardBody>
      <CardFooter>
        <ReactTable
          columns={[
            {
              Header: "Start",
              accessor: "startDate",
              maxWidth: 80,
            },
            {
              Header: "End",
              accessor: "endDate",
              maxWidth: 80,
            },
            {
              Header: "Required",
              accessor: "noRequiredWorkers",
              minWidth: 60,
              Cell: (row) => {
                return (
                  <RequiredWorkersCell
                    value={row.value}
                    businessName={business.name}
                    noRequiredWorkers={row.original.noRequiredWorkers}
                    _id={row.original._id}
                    shiftStart={row.original.start}
                    shiftEnd={row.original.end}
                    onChange={(newNoPeople) => {
                      setShiftsData(
                        shiftsData.map((s) => {
                          let openDate = moment(row.original.start)
                            .set({ hour: 23, minute: 59 })
                            .format();
                          let closeDate = moment(row.original.start)
                            .set({ hour: 0, minute: 0 })
                            .format();
                          if (s._id === row.original._id) {
                            s.noRequiredWorkers = newNoPeople;
                            if (
                              newNoPeople &&
                              newNoPeople > row.original.noSelected
                            ) {
                              s.closedDate = new Date(openDate);
                            } else if (newNoPeople) {
                              s.closedDate = new Date(closeDate);
                            }
                          }
                          s.actions = RenderActionButtons(s, business);

                          return s;
                        })
                      );
                    }}
                  />
                );
              },
            },
            {
              Header: "Confirmed",
              accessor: "noSelected",
              minWidth: 60,
            },
            {
              Header: "Backup",
              accessor: "noBackup",
              minWidth: 60,
            },
            // {
            //   Header: "Available",
            //   accessor: "noApplied",
            //   minWidth: 60,
            // },
            // {
            //   Header: "UnScheduled",
            //   accessor: "noUnScheduled",
            //   minWidth: 60,
            // },
            {
              Header: "C/I",
              accessor: "noCheckIn",
              maxWidth: 60,
            },
            {
              Header: "C/O",
              accessor: "noCheckOut",
              maxWidth: 60,
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
              width: 90,
            },
          ]}
          data={shiftsData}
          defaultPageSize={30}
          minRows={0}
          showPagination={false}
          className="-striped -highlight primary-pagination"
          getTrProps={(state, rowInfo, column) => {
            if (rowInfo) {
              return {
                style: {
                  background:
                    rowInfo.row.noRequiredWorkers !== 0 &&
                    !rowInfo.row.noRequiredWorkers
                      ? "#f7f2e4"
                      : rowInfo.row.noSelected >= rowInfo.row.noRequiredWorkers
                      ? "#e4f7e7"
                      : rowInfo.row.noRequiredWorkers === 0 ||
                        (rowInfo.row.noRequiredWorkers === 0 &&
                          shiftsData.noSelected > 0)
                      ? "#f0eeed"
                      : "",
                  color:
                    parseInt(rowInfo.row.noSelected) >=
                    parseInt(rowInfo.row.noRequiredWorkers)
                      ? "green"
                      : parseInt(rowInfo.row.noRequiredWorkers) >
                        parseInt(rowInfo.row.noSelected)
                      ? "red"
                      : "#333333",
                },
              };
            }
          }}
        />
      </CardFooter>
    </Card>
  );
};

export default function AllBusiness() {
  const [businesses, setBusinesses] = useState([]);
  const { business } = useBusiness();
  const agency = business?.profile?.agency;
  const [businessList, setBusinessList] = useState([]);
  const [date, setDate] = useState(
    localStorage.getItem("selectedDate")
      ? localStorage.getItem("selectedDate")
      : new Date()
  );
  const [isLoading, setIsLoading] = useState(false);
  const { addAlert } = useModal();
  const { socket } = useSocket();

  useEffect(() => {
    if (business?.profile) {
      console.log("profile", business?.profile);

      const businessList = business?.profile?.length
        ? business.profile?.map((p) => p?.business?._id)
        : [];
      setBusinessList(businessList);
      console.log("business list", businessList);
    }
  }, [business]);

  useEffect(() => {
    businessList?.length && loadLiveBusinesses(agency, date, businessList);
  }, [businessList]);

  const liveUpdate = (data) => {
    if (data.source._id === business.user._id) {
      console.log("sender is same do nothing");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (socket) {
      const listneningSockets = [
        "selectedForWork",
        "deSelectedForWork",
        "shiftUpdated",
        "apply",
        "accepted",
        "rejected",
        "signIn",
      ];

      listneningSockets.map((soc) => {
        socket.on(soc, (data) => {
          liveUpdate(data) && loadLiveBusinesses(agency, date);
        });
      });
    }
  }, [socket]);

  const loadLiveBusinesses = function (agency, date) {
    console.log("load live business start");
    businessList.length &&
      api()
        .post(`/business/all-shift-requests/`, {
          start: startOfDay(date),
          end: startOfNextDay(date),
          day: getDay(date),
          business: businessList,
        })
        .then(({ data }) => {
          //manupulate data in case the start and end date are null for a shift
          console.log("load live business complete", data);
          let newBusinessData = data.map((business) => {
            return {
              ...business,
              shifts: business.shifts.map((shift) => {
                if (!shift.start) {
                  let currentWeekDaySchedule = shift.weekSchedule.find(
                    (s) => s.day === getDay(date)
                  );
                  shift.start = moment(date).set({
                    hour: currentWeekDaySchedule?.start.hour,
                    minute: currentWeekDaySchedule.start.minute,
                  });
                  shift.end = moment(date).set({
                    hour: currentWeekDaySchedule?.end.hour,
                    minute: currentWeekDaySchedule.end.minute,
                  });

                  if (shift.start.isAfter(shift.end)) {
                    shift.end = moment(shift.end).add(1, "days");
                  }
                }
                return shift;
              }),
            };
          });

          setIsLoading(false);
          setBusinesses(newBusinessData);
        })
        .catch((e) => {
          addAlert({
            error: true,
            title: e.response?.data?.message || e.message,
          });
          setIsLoading(false);
          console.log(e);
        });
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <Card className="no-transition">
            <CardHeader>
              <div
                className="d-flex justify-content-between"
                style={{ alignItems: "center" }}
              >
                <CardTitle tag="h4">All Business shift requests</CardTitle>
              </div>
              <CustomCalendar
                initialDate={date}
                onChangeDate={(date) => {
                  setDate(date);
                  localStorage.setItem("selectedDate", date);
                  loadLiveBusinesses(agency, date);
                }}
              />
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <LoadingOverlay />
              ) : (
                <Row>
                  {businesses.length > 0 ? (
                    businesses.map((business, i) => {
                      return (
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                          <Module
                            business={business}
                            key={i}
                            index={i}
                            currentDate={date}
                            onUpdate={() => {
                              loadLiveBusinesses(agency, date);
                            }}
                          />
                        </Col>
                      );
                    })
                  ) : (
                    <p>No workers requested for the day.</p>
                  )}
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
