 export const statusEnum = {
   available: 0,
   unavailable: 1,
   noResponse: 2,
   selectedForWork: {
     Applied: 0 /****when user applies to a shift */,
     selected: 1 /***when user is selected for shift */,
     Backup: 2, //when backup is created
     Rejected: 3, //when rejected
     UnScheduled: 4, //when unscheduled by admins
     Dropped: 5, //when shift dropped by user
     computerDropped: 6,
   },
   suspended: 4,
 };

  export const UnScheduleReasonList = [
    { value: 0, label: "User Bailed" },
    { value: 1, label: "User Didn't Pickup Call" },
    { value: 3, label: "Scheduled By Mistake" },
    { value: 4, label: "Cancelled Due To Less Work" },
    { value: 5, label: "Cancelled By Plant" },
    { value: 6, label: "User Was Sent Back" },
    { value: 2, label: "User DidNot Go For Other Reasons" },
  ];